import React, {
  useRef,
  useState,
  useContext,
  useEffect,
  useMemo
} from 'react'
import dayjs from 'dayjs';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { toast } from 'react-toastify';

import "./style.scss"

//Components
import { CModal } from 'components';
import { ModalLoader } from 'components/organism';
import ModalPJP from "./modal-pjp"
import ModalHotListing from "./modal-hot-listing"
import ModalConfirmationHotListing from "./modal-confirmation-hot-listing"
import ModalTransferListing from "./modal-transfer-listing"
import ModalSuccessTransferListing from "./modal-success-transfer-listing"

//Assets
import RoundDivider from "assets/round-divider.png";
import { ReactComponent as ThreeDots } from "assets/three-dots.svg";
import { ReactComponent as UbahListingIcon } from "assets/ubah-listing.svg";
import { ReactComponent as TandaiTerjualTersewaIcon } from "assets/tandai-terjual-tersewa.svg";
import { ReactComponent as CrossIcon } from "assets/cross.svg";
import { ReactComponent as HandshakeIcon } from "assets/handshake-icon.svg";
import { ReactComponent as GalleryIcon } from "assets/gallery.svg";
import { ReactComponent as IconTransferListing } from "assets/icon-transfer-listing.svg";
import { ReactComponent as LogoCentury } from "assets/logo-century-white.svg";
import { ReactComponent as HotListingLogo } from "assets/fire.svg";
import { ReactComponent as NewOptions } from "assets/dot-v1.svg";
import LetterSealWhitePNG from "assets/letter-seal-white.png";
import PinRed from "assets/pin-red.png";

// Context
import { useDetailListingCtx } from "../../Context"
import { GlobalContext } from 'contexts';

// Hooks
import useOutsideAlerter from 'hooks/useOutsideAlerter';

// Apis
import { createHotListing } from "actions"

const KontenTerjual = ({
  propertyImage = "https://images.unsplash.com/photo-1568605114967-8130f3a36994?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  userImage = "https://images.unsplash.com/photo-1568605114967-8130f3a36994?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  propertyLocation,
  companyName,
  userName,
  cardRef
}) => (
  <div ref={cardRef} className='fixed overflow-hidden left-[-200vw] top-0 z-10 w-[1080px] h-[1080px] bg-black-2'>
    <div className='relative py-[56px] px-[80px] bg-transparent overflow-hidden'>
      <LogoCentury className='w-[357.098px] h-[40px] mb-4' />
      <p className="text-white text-2xl">{companyName}</p>
      <img className='absolute z-20 top-0 right-[-30px]' src={LetterSealWhitePNG} alt="..." />
    </div>
    <div className='w-[1080px] h-[608px]'>
      <img className='w-full h-full object-cover' src={propertyImage} alt="property" />
    </div>
    <div className='relative h-[272px] px-[80px]'>
      <div className='text-5xl flex items-center mb-2 mt-8 text-gray-1'><span className='mr-2 bg-yellow-0 font-semibold rounded-lg text-black-2 py-[8px] px-[16px] text-6xl'>SOLD</span> by</div>
      <p className='text-5xl mb-6 text-white'>{userName}</p>
      <div className='flex items-center'><img className='mr-[4px]' src={PinRed} alt="..." /> <span className='text-lg text-white'>{propertyLocation}</span></div>
      <img className='absolute top-[-30rem] object-contain right-[-136px] w-[609.478px] h-[1037.445px] ' src={userImage} alt={userName} />
    </div>
  </div>
)

const OptionsButton = ({
  isHide,
  label,
  Icon,
  className,
  ...props
}) => {
  if (isHide) return null;
  return (
    <button
      {...props}
      className={`btn-edit-listing ${className}`}
    >{!!Icon && <Icon />} {label}</button>
  )
}

function Poin({
  score,
  created_at = "",
  handleArchiveListing,
  handleUnarchivedListing,
  list,
  filter,
  loading,
  handleOpenModal,
  propertyImage,
  userImage,
  propertyLocation,
  companyName,
  userName,
  propertyId,
  handleOpenModalDelete
}) {
  const { setShowLoading } = useContext(GlobalContext)
  const dropdownRef = useRef(null);
  const cardRef = useRef(null);

  const { goToUbahListing, agents, } = useDetailListingCtx()
  const [isOpenModalPjp, setIsOpenModalPjp] = useState(false)
  const [isOpenModalTransferListing, setIsOpenModalTransferListing] = useState(false)
  const [isSuccessTransfer, setIsSuccessTransfer] = useState(false)

  // State hot listing
  const [isOpenModalHotListing, setIsOpenModalHotListing] = useState(false);
  const [isOpenModalConfirmationHotListing, setIsOpenModalConfirmationHotListing] = useState(false);

  const [isShowEditListing, setIsShowEditListing] = React.useState(false)
  const [isShowModalKonfirmasi, setIsShowModalKonfirmasi] = React.useState(false)

  const [hotListingReason, setHotListingReason] = useState("");

  const [isShowNewOption, setShowNewOptions] = useState((() => {
    let counter = localStorage.getItem('detail-listing-new-option-listener') ?? 0;

    return Number(counter)
  })());

  const isSell = list?.property_type?.id === 1;

  const isArchive = list?.is_archived;

  const DataKontenTerjual = {
    propertyImage,
    userImage,
    propertyLocation,
    companyName,
    userName
  }

  const convertToPdf = async () => {
    try {
      setShowLoading(true)

      const input = cardRef.current;

      const w = 1080
      const h = 1080

      const scale = 1;
      const canvasOptions = {
        scale: scale,
        useCORS: true,
        allowTaint: true,
        backgroundColor: null,
      };
      const pdfOptions = {
        orientation: 'p',
        unit: 'mm',
        format: [w, h],
      };

      await html2canvas(input, canvasOptions).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF(pdfOptions);
        pdf.addImage(imgData, 'PNG', 0, 0, w, h);
        pdf.save('front-card.pdf');
      });
    } catch (error) {
      console.log(error)
    } finally {
      setShowLoading(false)
    }
  };

  const handleTanggalPelaporan = (date = "") => {
    if (!date) return "";
    let result = dayjs(date).format("D MMM, YYYY")

    if (result == "Invalid Date") {
      const newDate = date.replace(/-/g, '/')
      result = dayjs(newDate).format("D MMM, YYYY")
    }

    return result
  }

  const toggle = () => {
    setIsShowModalKonfirmasi(!isShowModalKonfirmasi);
  }

  const handleArchive = () => {
    if (isArchive) {
      handleUnarchivedListing(list?.slug)
    } else {
      handleArchiveListing(list?.slug)
    }

    setIsShowModalKonfirmasi(false)
  }

  const handleOpenModalHotListing = () => setIsOpenModalHotListing(true)
  const handleCloseModalHotListing = () => setIsOpenModalHotListing(false)
  const handleOpenModalConfirmationHotListing = () => {
    handleCloseModalHotListing()
    setIsOpenModalConfirmationHotListing(true)
  }
  const handleCloseModalConfirmationHotListing = () => {
    handleOpenModalHotListing()
    setIsOpenModalConfirmationHotListing(false)
  }

  const handleCreateHotListing = async () => {
    try {
      setShowLoading(true)
      const payload = {
        "hot_listing": {
          "property_id": propertyId,
          "reason": hotListingReason
        }
      }
      await createHotListing(payload)

      toast.success(`Hot listing dengan id properti ${propertyId}, berhasil dibuat.`);
      setIsOpenModalHotListing(false)
      setIsOpenModalConfirmationHotListing(false)
    } catch (error) {
      console.log(error)
    } finally {
      setShowLoading(false)
    }
  }

  const handleShowEditListing = () => {
    localStorage.setItem('detail-listing-new-option-listener', ListOptions.length)
    setShowNewOptions(ListOptions.length)
    setIsShowEditListing(true)
  }

  // useMemo
  const ListOptions = [
    {
      isHide: filter === 'inactive',
      onClick: goToUbahListing,
      Icon: UbahListingIcon,
      label: "Ubah Listing Ini"
    },
    {
      onClick: () => setIsOpenModalPjp(true),
      Icon: HandshakeIcon,
      label: "Buat PJP"
    },
    {
      onClick: () => setIsOpenModalTransferListing(true),
      Icon: IconTransferListing,
      label: "Transfer Listing"
    },
    {
      onClick: convertToPdf,
      Icon: GalleryIcon,
      label: "Buat Konten Terjual"
    },
    // {
    //   onClick: handleOpenModalHotListing,
    //   Icon: HotListingLogo,
    //   label: "Tandai Hot Listing",
    //   isHide: filter === "hot-listing",
    // },
    // {
    //   onClick: handleOpenModal,
    //   Icon: TandaiTerjualTersewaIcon,
    //   label: `Tandai ${isSell ? "Terjual" : "Tersewa"} Listing Ini`
    // },
    {
      onClick: handleOpenModalDelete,
      isHide: filter !== "active",
      label: "Hapus Listing Ini",
      className: "!text-red-0"
    },
  ]

  useEffect(() => {
    return () => {
      setHotListingReason("")
    }
  }, [])

  useOutsideAlerter(dropdownRef, () => setIsShowEditListing(false))
  return (
    <div>
      {isArchive && (<div className='wrapper-btn-not-active'>
        <p className='btn-not-active-text'>Listing ini telah {isSell ? "terjual" : "tersewa"}</p>
        <div className='flex items-center'>
          <button onClick={handleArchive} className='btn-not-active mr-[16px]'>
            Aktifkan Listing
          </button>
          <button className='btn-edit-listing' onClick={goToUbahListing}><UbahListingIcon /> <span>Ubah Listing Ini</span></button>
        </div>
      </div>)}
      <div className='detail-listing-point flex justify-between mb-[16px] w-[100%]'>
        <div className='flex items-center'>
          <p className='text-gray-0 text-xs'>Detail listing</p>
          <img src={RoundDivider} alt="dot divider" className='mr-[10px] ml-[10px]' />
          <div>
            <span className='text-gray-0 text-xs mr-[8px]'>Dibuat pada</span>
            <span>{handleTanggalPelaporan(created_at)}</span>
          </div>
          <img src={RoundDivider} alt="dot divider" className='mr-[10px] ml-[10px]' />
          <div className='point-card-bg flex p-[8px] justify-center items-center rounded-lg'>
            <span className='mr-[8px] text-xs font-semibold text-sm'>{score}</span>
            <span className='mr-[8px] text-xs font-normal text-xs'>poin</span>
          </div>
        </div>

        {(!isArchive && filter !== "inactive") && <div className='wrapper-btn-options' ref={dropdownRef}>
          <button className='btn-options' onClick={handleShowEditListing} >
            {isShowNewOption < ListOptions.length && <NewOptions className='new-options' />}
            <ThreeDots />
          </button>

          {isShowEditListing && (
            <div className='wrapper-edit-listing'>
              {ListOptions.map((list, i) => <OptionsButton key={`options-button-${i}`} {...list} />)}
            </div>
          )}
        </div>}

        {isShowModalKonfirmasi && <CModal
          id="modal-konfirmasi-tandai-listing"
          toggle={toggle}
        >
          <div className='modal-content'>
            <div className='close-icon-wrapper'>
              <CrossIcon onClick={toggle} />
            </div>

            {!isArchive ?
              <>
                <p className='title'>Yakin tandai listing ini telah {isSell ? "terjual" : "tersewa"}?</p>
                <p className='info'>
                  Jika memilih <span>"YA"</span>, listing ini akan dipindahkan ke daftar {isSell ? "terjual" : "tersewa"}. Kamu bisa mengaktifkan listing ini lagi di tab listing {isSell ? "terjual" : "tersewa"}.
                </p>
              </>
              :
              <>
                <p className='title'>Yakin aktifkan listing ini?</p>
                <p className='info'>
                  Jika memilih <span>"YA"</span>, listing ini akan dipindahkan ke daftar listing aktif.
                </p>
              </>
            }
            <div className='button-wrapper'>
              <button className='ya' onClick={toggle}>
                <span>Batal</span>
              </button>
              <button className='batal' onClick={handleArchive}>
                {!isArchive ?
                  <span>Ya, Listing Ini {isSell ? "Terjual" : "Tersewa"}</span> : <span>Ya, Aktifkan Listing Ini</span>}
              </button>
            </div>
            {loading && <ModalLoader
              modalContentClassName='detail-listing-point-modal-loader'
              open={true}
            />}
          </div>
        </CModal>}

        <ModalHotListing
          isOpen={isOpenModalHotListing}
          onClose={handleCloseModalHotListing}
          setHotListingReason={setHotListingReason}
          hotListingReason={hotListingReason}
          onContinue={handleOpenModalConfirmationHotListing}
        />
        <ModalConfirmationHotListing
          isOpen={isOpenModalConfirmationHotListing}
          onClose={handleCloseModalConfirmationHotListing}
          onContinue={handleCreateHotListing}
        />
        <ModalPJP onClose={() => setIsOpenModalPjp(false)} propertyId={propertyId} isOpen={isOpenModalPjp} />
        <ModalTransferListing
          onClose={() => { setIsOpenModalTransferListing(false) }}
          agents={agents?.data ?? []}
          slug={list?.slug ?? ""}
          onSuccess={() => {
            setIsOpenModalTransferListing(false)
            setIsSuccessTransfer(true)
          }}
          isOpen={isOpenModalTransferListing}
        />
        <ModalSuccessTransferListing
          onClose={() => { setIsSuccessTransfer(false) }}
          isOpen={isSuccessTransfer}
        />
        <KontenTerjual cardRef={cardRef} {...DataKontenTerjual} />
      </div>
    </div>
  )
}

export default Poin