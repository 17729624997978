import React, { useContext, useState, useMemo } from 'react'
import dayjs from 'dayjs'
import { toast } from 'react-toastify'

//Styles
import Styles from './leads-management.module.scss'

//Assets
import { ReactComponent as Callendar } from 'assets/calendar-v2.svg'
import { ReactComponent as NextIcon } from 'assets/next.svg';
import { ReactComponent as PrevIcon } from 'assets/previous.svg';
import { ReactComponent as ClockIcon } from 'assets/clock-1.svg';
import { ReactComponent as HouseIcon } from 'assets/house-line.svg';
import { ReactComponent as IconAllAgent } from 'assets/all-agent.svg';
import { ReactComponent as IconAllAgentActive } from 'assets/all-agent-active.svg';
import { ReactComponent as IconReset } from 'assets/icon-reset.svg';
import CheckCircle from 'assets/CheckCircle.png';

// Context
import { GlobalContext } from "contexts";
import {
  LeadsManagementProvider,
  useLeadsManagementState,
  useLeadsManagementMethod
} from "./useLeadsManagementContext"
import { formatTanggalV1 } from "utils/formatTanggal";
import { useTandaiMenanggapi } from 'api/leads-management/mutation';

// Components
import Button from 'components/v1/Button';
import ModalChooseSchedule from "./modal-choose-schedule"
import ModalPilihAgent from "./modal-pilih-agent"

const COLWIDTH = {
  no: "w-[20px]",
  asalLeads: "w-[205px]",
  marketingName: "w-[250px]",
  customerName: "w-[250px]",
  lamaLeads: "w-[130px]",
  buttonLihat: "w-[167px]",
}

const COLWIDTHMenunggu = {
  no: "w-[20px]",
  asalLeads: "w-[190px]",
  marketingName: "w-[250px]",
  customerName: "w-[250px]",
  lamaLeads: "w-[120px]",
  buttonLihat: "w-[192px]",
}

const RowHeader = () => {
  return (
    <div className={Styles['header-row']}>
      <div className={`${Styles['header-cell']} ${COLWIDTH.no}`}><span>No</span></div>
      <div className={`${Styles['header-cell']} ${COLWIDTH.asalLeads}`}><span>Asal leads</span></div>
      <div className={`${Styles['header-cell']} ${COLWIDTH.marketingName}`}><span>Marketing</span></div>
      <div className={`${Styles['header-cell']} ${COLWIDTH.customerName}`}><span>Nama customer</span></div>
      <div className={`${Styles['header-cell']} ${COLWIDTH.lamaLeads}`}><span>Lama leads</span></div>
      <div className={`${Styles['header-cell']} ${COLWIDTH.buttonLihat}`} />
    </div>
  )
}

const RowBody = ({
  no,
  asalLeads,
  marketing,
  namaCustomer,
  lamaLeads,
  handleNavigate,
  urlLeads,
  id,
  visitSchedules,
  LEADS_STATUS,
  leadStatus,
  handleGetLeads
}) => {
  const [openModal, setOpenModal] = useState(false);

  const {
    mutate: mutateTandaiMenanggapi
  } = useTandaiMenanggapi()

  const handleTandaiMenanggapi = () => {
    mutateTandaiMenanggapi(id, {
      onSuccess: () => {
        handleGetLeads()
        toast.success("Berhasil menanggapi")
      },
      onError: (error) => {
        toast.error(error?.message ?? "Gagal menanggapi")
      }
    })
  }

  if (leadStatus === LEADS_STATUS.menunggu) {
    return (
      <div className={`${Styles['body-row']}`}>
        <div className={`${Styles['body-cell']} ${COLWIDTHMenunggu.no}`}><span>{no}</span></div>
        <div className={`${Styles['body-cell']} ${Styles['asal-leads']} ${COLWIDTHMenunggu.asalLeads}`}><span className={`${!asalLeads ? "!bg-transparent" : ""}`}>{asalLeads}</span></div>
        <div className={`${Styles['body-cell']} ${COLWIDTHMenunggu.marketingName}`}><span>{marketing}</span></div>
        <div className={`${Styles['body-cell']} ${COLWIDTHMenunggu.customerName}`}><span>{namaCustomer}</span></div>
        <div className={`${Styles['body-cell']} ${COLWIDTHMenunggu.lamaLeads}`}>
          <span>{lamaLeads}</span>
        </div>
        <div className={`${Styles['body-cell']} ${COLWIDTHMenunggu.buttonLihat}`}>
          {!!visitSchedules?.length && <Button onClick={() => setOpenModal(true)} icon={ClockIcon} size="xs" className="mr-2" text="Jadwal" />}
          <Button onClick={() => handleNavigate(urlLeads)} disabled={!urlLeads} icon={HouseIcon} size="xs" type="secondary" text="Listing" />
          <Button onClick={handleTandaiMenanggapi} icon={CheckCircle} size="xs" type="secondary" text="Menanggapi" />
        </div>

        <ModalChooseSchedule visitSchedules={visitSchedules} id={id} isOpen={openModal} onClose={() => setOpenModal(false)} />
      </div>
    )
  }

  return (
    <div className={`${Styles['body-row']}`}>
      <div className={`${Styles['body-cell']} ${COLWIDTH.no}`}><span>{no}</span></div>
      <div className={`${Styles['body-cell']} ${Styles['asal-leads']} ${COLWIDTH.asalLeads}`}><span className={`${!asalLeads ? "!bg-transparent" : ""}`}>{asalLeads}</span></div>
      <div className={`${Styles['body-cell']} ${COLWIDTH.marketingName}`}><span>{marketing}</span></div>
      <div className={`${Styles['body-cell']} ${COLWIDTH.customerName}`}><span>{namaCustomer}</span></div>
      <div className={`${Styles['body-cell']} ${COLWIDTH.lamaLeads}`}>
        <span>{lamaLeads}</span>
      </div>
      <div className={`${Styles['body-cell']} ${COLWIDTH.buttonLihat}`}>
        {!!visitSchedules?.length && <Button onClick={() => setOpenModal(true)} icon={ClockIcon} size="xs" className="mr-2" text="Jadwal" />}
        <Button onClick={() => handleNavigate(urlLeads)} disabled={!urlLeads} icon={HouseIcon} size="xs" type="secondary" text="Listing" />
      </div>

      <ModalChooseSchedule visitSchedules={visitSchedules} id={id} isOpen={openModal} onClose={() => setOpenModal(false)} />
    </div>
  )
}

const Table = ({ data, ...rest }) => {
  const handleNavigate = (url) => {
    window.location.href = url
  };

  return (
    <div className={`mb-[124px] ${Styles['table']}`}>
      <RowHeader />

      {!!data.length && data.map((item, index) => {
        return (
          <RowBody
            {...item}
            key={`table-${index}`}
            handleNavigate={handleNavigate}
            no={index + 1}
            {...rest}
          />
        )
      })}
    </div>
  )
}

const LeadsManagement = () => {
  const { profile } = useContext(GlobalContext);
  const {
    page,
    totalPage,
    LEADS_STATUS,
    listLeads,
    agents,
    params
  } = useLeadsManagementState()

  const {
    handleNextPage,
    handlePrevPage,
    handleChangeLeadStatus,
    handleGetLeads,
    handleChangeAgen,
    setParams
  } = useLeadsManagementMethod()

  const [inputPage, setInputPage] = useState(page)
  const [isOpenModalAgent, setIsOpenModalAgent] = useState(false)
  const [selectedAgen, setSelectedAgen] = useState(null)

  const formatLamaLeads = (differenceInDays, currentDate, givenDate, createdAt) => {
    if (differenceInDays < 1) {
      const newGivenDate = dayjs(createdAt);
      const differenceInHours = currentDate.diff(newGivenDate, 'hour');
      return `${differenceInHours} jam lalu`;
    } else if (differenceInDays < 30) {
      return `${differenceInDays} hari lalu`;
    } else if (differenceInDays < 365) {
      const differenceInMonth = currentDate.diff(givenDate, 'month');
      return `${differenceInMonth} bulan lalu`;
    } else {
      const differenceInYear = currentDate.diff(givenDate, 'year');
      return `${differenceInYear} tahun lalu`;
    }
  }

  const LIST_LEADS = useMemo(() => {
    if (!listLeads?.length) return [];

    return listLeads.map(leads => {
      const currentDate = dayjs();
      const givenDate = formatTanggalV1(leads?.created_at);
      const differenceInDays = dayjs().diff(leads?.created_at, 'day')

      return {
        asalLeads: leads?.kind ?? "",
        marketing: leads?.user?.full_name ?? "",
        namaCustomer: leads?.name ?? "",
        lamaLeads: formatLamaLeads(differenceInDays, currentDate, givenDate, leads?.created_at),
        urlLeads: leads?.property?.links?.original_url,
        id: leads?.id,
        visitSchedules: leads?.visit_schedules,
      }
    })
  }, [listLeads])

  return (
    <>
      <div className={Styles['wrapper']}>
        <div className={Styles['header']}>
          <h1 className={Styles['title']}>Leads Management</h1>
          <h1 className={Styles['welcome']}>Selamat datang kembali, {profile?.full_name}!</h1>

          <div className={Styles['actions-wrapper']}>
            <div className={Styles['btns-wrapper']}>
              <button onClick={() => handleChangeLeadStatus(LEADS_STATUS.menunggu)} className={`${Styles['btn']} ${Styles[params.lead_status === LEADS_STATUS.menunggu ? "active" : '']}`}><span>Menunggu</span></button>
              <button onClick={() => handleChangeLeadStatus(LEADS_STATUS.ditanggapi)} className={`${Styles['btn']} ${Styles[params.lead_status === LEADS_STATUS.ditanggapi ? "active" : '']}`}><span>Ditanggapi</span></button>
            </div>

            {/* <button className={Styles['btn-calendar']}>
              <Callendar />
              <span>Pilih periode</span>
            </button> */}

            <div className='flex items-center'>
              <Button
                text={!!params?.full_name ? params.full_name : "Semua Marketing"}
                icon={!!params?.full_name ? IconAllAgentActive : IconAllAgent}
                type={!!params?.full_name ? "primary" : "secondary"}
                size="xl"
                className="mr-2"
                onClick={() => setIsOpenModalAgent(true)}
              />
              <Button
                icon={IconReset}
                type="negative"
                size="xl"
                iconClassName='!m-0'
                disabled={!params?.full_name}
                onClick={() => setParams({ ...params, page: 1, full_name: "" })}
              />
            </div>
          </div>
        </div>

        <div className={`${Styles['content']}`}>
          <Table
            data={LIST_LEADS}
            LEADS_STATUS={LEADS_STATUS}
            leadStatus={params.leadStatus}
            handleGetLeads={handleGetLeads}
          />
        </div>

        {/** FOOTER */}
        <div className='fixed justify-between left-[193px] bottom-0 w-[calc(100vw-193px)] bg-white py-[40px] px-[32px] flex items-center box-shadow-1 border-[0.5px] border-solid border-white-0'>
          <div className='flex items-center'>
            <p className='text-sm text-gray-2 mr-[32px]'>Pergi ke halaman</p>
            <div className='flex items-center border-gray-2 border-[1px] border-solid rounded-[8px] px-4 py-[10px]'>
              <input min={1} onChange={(e) => setInputPage(e.target.value ?? "")} value={inputPage ?? 1} max={totalPage} className='w-[32px] h-[20px] mr-2' placeholder='0' type='number' />
              <NextIcon onClick={() => setParams({ ...params, page: inputPage })} className='w-[14px] h-[14px] object-contain cursor-pointer' />
            </div>
          </div>

          <div className='flex items-center'>
            <button onClick={handlePrevPage} className='flex items-center text-sm text-gray-0 border-[1px] border-solid rounded-md border-black-3 px-4 py-[10px]'><PrevIcon className='w-[14px] h-[14px] object-contain mr-2' /> Sebelumnya</button>
            <p className='px-[32px] text-sm'>{params.page} <span className='text-gray-2'>dari</span> {totalPage} <span className='text-gray-2'>halaman</span></p>
            <button onClick={handleNextPage} className='flex items-center text-sm text-gray-0 border-[1px] border-solid rounded-md border-black-3 px-4 py-[10px]'>Selanjutnya <NextIcon className='w-[14px] h-[14px] object-contain ml-2' /></button>
          </div>
        </div>
      </div>

      <ModalPilihAgent
        onClose={() => { setIsOpenModalAgent(false) }}
        agents={agents}
        onSelect={(agent) => {
          handleChangeAgen(agent)
          setIsOpenModalAgent(false)
        }}
        isOpen={isOpenModalAgent}
        selectedAgen={selectedAgen}
        setSelectedAgen={setSelectedAgen}
      />
    </>
  )
}

export default ({ ...props }) => <LeadsManagementProvider><LeadsManagement {...props} /></LeadsManagementProvider>
