import React, { useState } from 'react'

import "./style.scss";

//Components
import DetailListingPoin from './Poin';
import GambaranUmum from './GambaranUmum';
import Address from './Address';
import UkuranProperti from './UkuranProperti';
import Deskripsi from './Deskripsi';
import FasilitasRumah from './FasilitasRumah';
import FasilitasSekitar from './FasilitasSekitar';
import KontakOwner from './KontakOwner';
import UbahListing from './UbahListing';
import BuatBanner from './BuatBanner';
import CModal from 'components/modal';
import Button from "components/atomic/Button";
import ButtonV1 from 'components/v1/Button';
import ButtonHapusHotListing from "./HapusHotListing"

import { ReactComponent as CrossIcon } from "assets/cross.svg";

//Context
import { useDetailListingCtx } from "../Context"
import { result } from 'lodash';

function DetailListing() {
  const {
    listDetail,
    loading,
    _getActivedListing,
    _getMarkerListing,
    _getDeleteListing,
    filter,
    goToUbahListing,
    handleDeleteHotListing,
  } = useDetailListingCtx()

  const [openModal, setOpenModal] = useState(false)
  const [openModalAktifkan, setOpenModalAktifkan] = useState(false)
  const [openModalMarker, setOpenModalMarker] = useState(false)
  const [openModalDelete, setOpenModalDelete] = useState(false)

  const handleCloseModal = () => {
    setOpenModal(false)
  }
  const handleOpenModal = () => {
    setOpenModal(true)
  }

  const handleOpenModalAktifkan = () => {
    setOpenModalAktifkan(!openModalAktifkan)
  }
  const handleOpenModalMarker = () => {
    setOpenModalMarker(!openModalMarker)
  }
  const handleOpenModalDelete = () => {
    setOpenModalDelete(!openModalDelete)
  }

  const activedAction = () => {

    const slugList = result(listDetail, 'slug', '')
    handleOpenModalAktifkan()
    _getActivedListing(slugList);
  }

  const markerAction = () => {

    const slugList = result(listDetail, 'slug', '')
    handleOpenModalMarker()
    _getMarkerListing(slugList);
  }

  const deletedAction = () => {

    const slugList = result(listDetail, 'slug', '')
    handleOpenModalDelete()
    _getDeleteListing(slugList);
  }
  const isSell = result(listDetail, 'property_type.id', '') === 1;

  return (
    <div className={`detail-listing-wrapper ${!listDetail ? "hide" : null}`}>
      <div className='wrapper-btn-not-inactive'>
        {(filter === 'inactive' || filter === 'terjual' || filter === 'tersewa') && <button onClick={handleOpenModalAktifkan} className='btn-not-active'>
          Aktifkan
        </button>}
        {/* <button onClick={goToUbahListing} className='btn-not-active'>
            Ubah Listing
          </button> */}
        {filter === 'active' && <button onClick={handleOpenModal} className='btn-not-active'>
          Tandai {isSell ? "Terjual" : "Tersewa"}
        </button>}
        {(filter === 'inactive') && <button onClick={handleOpenModalDelete} className='btn-not-active-delete'>
          Hapus
        </button>}
      </div>

      <ButtonHapusHotListing
        filter={filter}
        haveReason={!!listDetail?.hotListingParent?.reason}
        hotListingId={listDetail?.hotListingParent?.id}
        handleDeleteHotListing={handleDeleteHotListing}
        listDetail={listDetail?.hotListingParent}
      />

      <DetailListingPoin
        score={listDetail?.score}
        created_at={listDetail?.created_at}
        loading={loading}
        handleOpenModal={handleOpenModal}
        filter={filter}
        propertyImage={listDetail?.summary?.property_images?.image?.url ?? ""}
        userImage={listDetail?.user?.user_image?.online_profile?.url ?? ""}
        propertyLocation={listDetail?.summary?.property_locations?.[0]?.street ?? ""}
        companyName={listDetail?.company?.name}
        userName={listDetail?.user?.full_name}
        propertyId={listDetail?.id}
        handleOpenModalDelete={handleOpenModalDelete}
        list={listDetail}
      />
      <GambaranUmum />

      <Address />
      <UkuranProperti />
      <Deskripsi description={listDetail?.description ?? ""} />
      <FasilitasRumah />
      <FasilitasSekitar />
      <KontakOwner />
      {/* <UbahListing /> */}
      <BuatBanner />

      {openModal && <CModal>
        <div id={"modal-active-nonactive"}>
          <div className='btn-wrapper'>
            <CrossIcon onClick={handleCloseModal} />
          </div>
          <div className='modal-content'>
            <p className='text'>Silakan melakukan pelaporan transaksi</p>
          </div>

          <div className='wrapper-btn'>
            <ButtonV1
              text="Ok"
              onClick={handleCloseModal}
            />
          </div>
        </div>

      </CModal>}
      {openModalAktifkan && <CModal>
        <div id={"modal-active-inactive"}>
          <div className='btn-wrapper'>
            <CrossIcon onClick={handleOpenModalAktifkan} />
          </div>
          <div className='modal-content'>
            <p className='text'>Yakin aktifkan listing ini?</p>
            <p className='subtext'>Jika memilih “<span>YA</span>”, listing ini akan dipindahkan ke daftar listing aktif.</p>
          </div>
          <div className='border-grey' />
          <div className='wrapper-btn'>
            <button onClick={handleOpenModalAktifkan} className='btn-cancel'>
              Batal
            </button>
            <button onClick={activedAction} className='btn-yes'>
              Ya, Aktifkan listing
            </button>
          </div>
        </div>

      </CModal>}
      {openModalMarker && <CModal>
        <div id={"modal-active-inactive"}>
          <div className='btn-wrapper'>
            <CrossIcon onClick={handleOpenModalMarker} />
          </div>
          <div className='modal-content'>
            <p className='text'>Yakin tandai listing ini telah {result(listDetail, 'property_type.id', '') === 2 ? 'tersewa' : 'terjual'}?</p>
            <p className='subtext'>Jika memilih “<span>YA</span>", listing ini akan dipindahkan ke daftar tersewa. Kamu bisa mengaktifkan listing ini lagi di tab listing tersewa.</p>
          </div>
          <div className='border-grey' />
          <div className='wrapper-btn'>
            <button onClick={handleOpenModalMarker} className='btn-cancel'>
              Batal
            </button>
            <button onClick={markerAction} className='btn-yes'>
              Ya, Listing ini {result(listDetail, 'property_type.id', '') === 2 ? 'tersewa' : 'terjual'}
            </button>
          </div>
        </div>

      </CModal>}
      {openModalDelete && <CModal>
        <div id={"modal-active-inactive"}>
          <div className='btn-wrapper'>
            <CrossIcon onClick={handleOpenModalDelete} />
          </div>
          <div className='modal-content'>
            <p className='text'>Yakin hapus listing ini?</p>
            <p className='subtext'>Kamu tidak akan melihat listing ini di daftar listing yang aktif.</p>
          </div>
          <div className='border-grey' />
          <div className='wrapper-btn'>
            <button onClick={handleOpenModalDelete} className='btn-cancel'>
              Batal
            </button>
            <button onClick={deletedAction} className='btn-yes-delete'>
              Ya, Hapus listing
            </button>
          </div>
        </div>

      </CModal>}
    </div>
  )
}

export default DetailListing