import React, { useState } from 'react'
import { toast } from 'react-toastify';

// Components
import Modal from "components/organism/modal-v2"
import Loader from 'components/molecules/Loader';
import Button from "components/v1/Button"

// Assets
import { ReactComponent as XIcon } from "assets/cross.svg"
import RadioBtn from "assets/radio-2.png"

// API
import { useTransferListing } from 'api/list-listing/mutation';


const ModalTransferListing = ({ agents, slug, onClose, isOpen, onSuccess, ...props }) => {
  const { mutate, isLoading } = useTransferListing()
  const [selectedAgen, setSelectedAgen] = useState(null)

  const handleSelectAgen = (agen) => {
    setSelectedAgen(agen?.id === selectedAgen?.id ? null : agen)
  }

  const handleSubmit = () => {
    const payload = {
      property: {
        user_id: selectedAgen?.id
      }
    }
    mutate({slug, payload}, {
      onSuccess: () => {
        onSuccess()
      },
      onError: () => {
        toast.error("Gagal melakukan transfer listing")
      }
    })
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} {...props}>
      {isLoading && <div className='absolute z-50 flex items-center justify-center w-full h-full bg-black bg-opacity-50'>
        <Loader />
      </div>}
      <div className="w-[426px] z-[42] bg-white rounded-md border-[0.5px] border-solid border-[#ECECEC] 
        shadow-[0px_1px_3px_0px_rgba(16,16,16,0.10),_0px_1px_2px_0px_rgba(16,16,16,0.06)]">

        <div className="flex justify-between px-4 py-2 border-b border-solid border-[#D8D8D9]">
          <p className='text-[17px] font-semibold'>Transfer ke siapa ?</p>
          <XIcon />
        </div>

        <div className='px-4 py-2'>
          <p className='text-[13px] text-[#808285] pb-1 mb-2 border-b-[0.5px] border-dashed border-[#D8D8D9]'>Pilih Marketing</p>
          <div className='pl-4 max-h-[378px] overflow-y-auto overflow-hidden'>
            {!!agents?.length && (agents.map(agen => {
              const selected = selectedAgen?.id === agen?.id
              return (
                <button onClick={() => handleSelectAgen(agen)} className='w-full flex items-center justify-between mb-4'>
                  <p className='text-[13px]'>{agen.full_name}</p>
                  {!!selected ? <img src={RadioBtn} alt='Radio btn' /> : <div className='rounded-[24px] border border-solid border-gray-0 bg-white w-4 h-4' />}
                </button>
              )
            }))}
          </div>
        </div>

        <div className={"flex items-center justify-end px-4 py-2 border-t border-solid border-[#D8D8D9]"}>
          <Button text="Batal" type="secondary" onClick={onClose} className="mr-2" />
          <Button onClick={handleSubmit} disabled={!selectedAgen} text="Transfer Listing" iconPosition="right" />
        </div>
      </div>
    </Modal>
  )
}

const Wrapper = ({ isOpen, ...props }) => {
  if (!isOpen) return null;
  return <ModalTransferListing isOpen={isOpen} {...props} />
}

export default Wrapper
