import React from 'react'

// Components
import Modal from "components/organism/modal-v2"
import Button from "components/v1/Button"

// Assets
import { ReactComponent as CheckList } from "assets/checklist-v2.svg"

const ModalSuccessTransferListing = ({ onClose, isOpen, ...props }) => {

  return (
    <Modal isOpen={isOpen} onClose={onClose} {...props}>
      <div className='flex flex-col items-center z-[42] bg-white-3 w-[426px] rounded py-6 px-8'>
        <CheckList className='w-[68px] h-[68px] object-contain mb-4' />
        <p className='text-[17px] text-center'>Listing ditransfer</p>

        <div className='w-full flex justify-end pt-2 mt-4 border-t border-solid border-[#D8D8D9]'>
          <Button onClick={onClose} text="Tutup" />
        </div>
      </div>
    </Modal>
  )
}

const Wrapper = ({ isOpen, ...props }) => {
  if (!isOpen) return null;
  return <ModalSuccessTransferListing isOpen={isOpen} {...props} />
}

export default Wrapper
