import React, { useEffect, useState, useContext, useRef } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import "react-tooltip/dist/react-tooltip.css";

//Components
import { ModalConfirmationV1 } from "components";

//Context
import { GlobalContext } from "contexts";

//Styles
import Styles from "./navbar.module.scss";

//Assets
import C21Gold from "assets/c21-gold.png";

import { ReactComponent as C21Logo } from "assets/logo-c21-navbar.svg";

import { ReactComponent as BuatListingActive } from "assets/nav-buat-listing-active.svg";
import { ReactComponent as BuatListingInActive } from "assets/nav-buat-listing-inactive.svg";
import { ReactComponent as One21Active } from "assets/on21-icon-active.svg";
import { ReactComponent as One21Inactive } from "assets/on21-icon-inactive.svg";

import IconLogout from "assets/IconLogout.png";
import IconCentury21 from "assets/century21.png";
import IconNotif from "assets/circle-red.png";

import IconDaftarListingActive from "assets/IconListListingActive.png";
import IconDaftarListingInactive from "assets/IconListListingInactive.png";
import IconRepportTransactionActive from "assets/IconRepportTransactionActive.png";
import IconRepportTransactionInactive from "assets/IconRepportTransactionInactive.png";
import IconLeadListingActive from "assets/IconLeadListingActive.png";
import IconLeadListingInactive from "assets/IconLeadListingInactive.png";
import IconTrainingActive from "assets/IconTrainingActive.png";
import IconTrainingInactive from "assets/IconTrainingInactive.png";
import IconKeymetricActive from "assets/IconKeymetricActive.png";
import IconKeymetricInactive from "assets/IconKeymetricInactive.png";
import IconListMarketingActive from "assets/IconListMarketingActive.png";
import IconListMarketingInactive from "assets/IconListMarketingInactive.png";
import IconInvoiceActive from "assets/IconInvoiceActive.png";
import IconInvoiceInactive from "assets/IconInvoiceInactive.png";
import IconIpafActive from "assets/IconIpafActive.png";
import IconIpafInactive from "assets/IconIpafInactive.png";
import IconProgressTrackerActive from "assets/IconProgressTrackerActive.png";
import IconProgressTrackerInactive from "assets/IconProgressTrackerInactive.png";
import IconLeadsManagementActive from "assets/IconLeadsManagementActive.png";
import IconLeadsManagementInactive from "assets/IconLeadsManagementInactive.png";

import { ReactComponent as DoubleLeftArrow } from 'assets/double-left-arrow.svg'

import useOutsideAlerter from 'hooks/useOutsideAlerter';

const showIpaf = process.env.REACT_APP_SHOW_IPAF === "true"
const showOne21Event = process.env.REACT_APP_SHOW_ONE21 === "true"

const NewTabList = [
  {
    url: "/",
    name: "Listing",
    active: IconDaftarListingActive,
    inactive: IconDaftarListingInactive,
    iconType: "png",
    isShow: true
  },
  {
    url: "/ereporting",
    name: "Transaksi",
    active: IconRepportTransactionActive,
    inactive: IconRepportTransactionInactive,
    iconType: "png",
    isShow: true
  },
  {
    url: "/upload-listing",
    name: "Buat Listing",
    active: BuatListingActive,
    inactive: BuatListingInActive,
    isShow: false
  },
  {
    url: "/leads-listing",
    name: "L. Listing",
    iconType: "png",
    active: IconLeadListingActive,
    inactive: IconLeadListingInactive,
    iconType: "png",
    isShow: true
  },
  {
    url: "/training",
    name: "Training",
    iconType: "png",
    active: IconTrainingActive,
    inactive: IconTrainingInactive,
    isShow: true
  },
  {
    url: "/key-metric-office-dashboard",
    name: "Key Metric",
    active: IconKeymetricActive,
    inactive: IconKeymetricInactive,
    isShow: true,
    iconType: "png",
  },
  {
    url: "/marketing",
    name: "Marketing",
    active: IconListMarketingActive,
    inactive: IconListMarketingInactive,
    iconType: "png",
    isShow: true
  },
  {
    url: "/invoice",
    name: "Invoice",
    active: IconInvoiceActive,
    inactive: IconInvoiceInactive,
    iconType: "png",
    isShow: true
  },
  {
    url: "/one21-event",
    name: "ONE21 Event",
    iconType: "img",
    active: One21Active,
    inactive: One21Inactive,
    isShow: showOne21Event
  },
  {
    url: "/ipaf-event",
    name: "IPAF Event",
    iconType: "png",
    active: IconIpafActive,
    inactive: IconIpafInactive,
    isShow: showIpaf
  },
  {
    url: "/progress-tracker",
    name: "Rumah123",
    iconType: "png",
    active: IconProgressTrackerActive,
    inactive: IconProgressTrackerInactive,
    isShow: true
  },
  {
    url: "/leads-management",
    name: "Leads",
    iconType: "png",
    active: IconLeadsManagementActive,
    inactive: IconLeadsManagementInactive,
    isShow: true
  }
]

const NewNavbarTab = ({
  list,
  profile,
  isHamburgerActive,
  showInvoice,
  showInvoiceAndEreporting,
  listingLeadsCounter,
  invoiceCounter
}) => {
  const location = useLocation();
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (location.pathname === list.url) {
      setActive(true)
    } else {
      setActive(false)
    }
  }, [location.pathname]);

  const isLeadsCiputra = list.name == "Leads MyCiputra";
  const isLeadsCiputraPrivate = profile?.company?.id == 228;
  const isInvoice = list.name === "Invoice";
  const isEreporting = list.name === "Lapor Transaksi";
  const isLeadsListing = list.name === "Leads Listing";

  if (!list?.isShow) return null;
  if (isLeadsCiputra && !isLeadsCiputraPrivate) return null;
  if (isInvoice && (!showInvoice || !showInvoiceAndEreporting)) return null;
  if (!showInvoiceAndEreporting && isEreporting) return null;

  if (list.iconType === "png") {
    return (
      <a href={list.url}>
        <div className={`${Styles['wrapper-outer']} ${active && Styles['active']} relative`}>
          <div className={`${Styles['wrapper-menu']} ${active && Styles['active']} relative`}>
            <div className={Styles['icon']}>
              {active ? <img src={list.active} alt={list.name} /> : <img src={list.inactive} alt={list.name} />}
            </div>
            <p className={Styles['label']}>{list.name}</p>
          </div>
          {isLeadsListing && !!+listingLeadsCounter && <img src={IconNotif} alt='Icon Notif' className={Styles["counter"]} />}
          {/* {isLeadsListing && !!+listingLeadsCounter && <span className={Styles["counter"]}>{listingLeadsCounter > 99 ? "99+" : listingLeadsCounter}</span>} */}
        </div>
      </a>
    )
  }
  return (
    <a href={list.url}>
      <div className={`${Styles['wrapper-outer']} ${active && Styles['active']} relative`}>
        <div className={`${Styles['wrapper-menu']} ${active && Styles['active']} relative`}>
          <div className={Styles['icon']}>
            {active ? <list.active /> : <list.inactive />}
          </div>
          <p className={Styles['label']}>{list.name}</p>
        </div>
        {isLeadsListing && !!+listingLeadsCounter && <img src={IconNotif} alt='Icon Notif' className={Styles["counter"]} />}
        {isInvoice && !!+invoiceCounter && <img src={IconNotif} alt='Icon Notif' className={Styles["counter"]} />}
      </div>
    </a>
  )
}

const NewNavbar = () => {
  const navRef = useRef(null);

  const navigate = useNavigate();
  const {
    logoutAction,
    profile,
    isHamburgerActive,
    setIsHamburgerActive,
    showInvoice,
    showInvoiceAndEreporting,
    listingLeadsCounter,
    invoiceCounter
  } = useContext(GlobalContext);
  const [openModalLogout, setOpenModalLogout] = useState(false)

  const logoutHandler = () => {
    logoutAction(() => navigate("/login"))
  }

  useOutsideAlerter(navRef, () => setIsHamburgerActive(false))
  return (
    <div
      ref={navRef}
      className={`${Styles['wrapper-new-navbar']}`}
    >
      <div className={Styles['wrapper-menus']}>
        <img className='mb-[3rem] w-[7.812rem] h-[0.875rem] object-contain' src={IconCentury21} alt='Icon Century 21' />
        <div className={Styles['wrapper-content-open']}>
          {NewTabList.map((item, index) => (
            <NewNavbarTab
              listingLeadsCounter={listingLeadsCounter}
              showInvoiceAndEreporting={showInvoiceAndEreporting}
              showInvoice={showInvoice}
              isHamburgerActive={isHamburgerActive}
              profile={profile}
              list={item}
              invoiceCounter={invoiceCounter}
              key={`navbar-${index}`}
            />
          ))}

        </div>
        <div className={`${Styles['wrapper-outer']} ${Styles['keluar']}`}>
          <div className={`${Styles['wrapper-menu']} ${Styles['keluar']}`} onClick={() => setOpenModalLogout(true)}>
            <div className={Styles['icon']}>
              <img src={IconLogout} alt='icon logout' />
            </div>

            <p className={`${Styles['label']} ${Styles['red']}`}>Keluar</p>
          </div>
        </div>
      </div>

      {openModalLogout ? <ModalConfirmationV1
        noImage={true}
        title="Kamu yakin ingin keluar?"
        confirmText="Oke"
        declineText="Batal"
        confirmAction={() => logoutHandler()}
        declineAction={() => setOpenModalLogout(false)}
        onClose={() => setOpenModalLogout(false)}
      /> : null}
    </div>
  )
}

// export default Navbar
export default NewNavbar
