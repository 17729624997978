import { useQuery } from '@tanstack/react-query'
import { getListManagement } from "./api"

export const useGetListManagement = ({ params }) => {
  return useQuery({
    queryKey: ['get-list-management', params],
    queryFn: () => getListManagement(params)
  })
}


