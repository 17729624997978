import {
  createContext,
  useState,
  useEffect,
  useContext,
  useMemo
} from "react";
import lodash from "lodash";

// Api
import { getMarketingAgent } from "actions"
import { useGetListManagement } from "api/leads-management/query";

// Context
import { GlobalContext } from "contexts";

const UseLeadsManagementSateContext = createContext(null);
const UseLeadsManagementMethodContext = createContext(null);

const LEADS_STATUS = {
  menunggu: "Menunggu",
  ditanggapi: "Ditanggapi",
}

export const LeadsManagementProvider = ({ children }) => {
  const { setShowLoading } = useContext(GlobalContext);

  const [params, setParams] = useState({
    page: 1,
    lead_status: "Menunggu",
    full_name: ""
  })

  const { data: dataListLeads, isFetching } = useGetListManagement({ params })

  const totalPage = useMemo(() => {
    if (!dataListLeads) return 0;

    const totalItem = dataListLeads?.headers?.["x-total"]
    const totalPage = lodash.ceil(totalItem / 10) || 0

    return totalPage
  }, [dataListLeads])

  const listLeads = useMemo(() => {
    if (!dataListLeads) return [];

    return dataListLeads?.data?.data ?? []
  }, [dataListLeads])

  const [agents, setAgents] = useState([])

  const getAgents = async () => {
    try {
      const list = await getMarketingAgent("?page=1&_limit=99999")

      const newData = list?.data ?? []

      setAgents(newData)
    } catch (error) {
      console.log(error)
    }
  }

  const handleNextPage = () => {
    if (params.page === totalPage) return;

    setParams({ ...params, page: params.page + 1 })
  }
  const handlePrevPage = () => {
    if (params.page === 1) return;

    setParams({ ...params, page: params.page - 1 })
  }

  const handleChangeLeadStatus = (status) => setParams({ ...params, page: 1, lead_status: status })
  const handleChangeAgen = (agen) => setParams({ ...params, page: 1, full_name: agen.full_name })

  useEffect(() => {
    setShowLoading(isFetching)
  }, [isFetching])

  useEffect(() => {
    getAgents()
  }, [])

  return (
    <UseLeadsManagementSateContext.Provider value={{
      LEADS_STATUS,
      listLeads,
      totalPage,
      agents,
      params
    }}>
      <UseLeadsManagementMethodContext.Provider value={{
        handleNextPage,
        handlePrevPage,
        handleChangeLeadStatus,
        handleChangeAgen,
        setParams
      }}>
        {children}
      </UseLeadsManagementMethodContext.Provider>
    </UseLeadsManagementSateContext.Provider>
  )
}

//Custom hooks for the values
export const useLeadsManagementState = () => {
  const ctx = useContext(UseLeadsManagementSateContext);

  if (!ctx) {
    throw new Error("useLeadsManagementState must be used within the ERProvider");
  }

  return ctx;
};

//Custom hooks for the functions
export const useLeadsManagementMethod = () => {
  const ctx = useContext(UseLeadsManagementMethodContext);

  if (!ctx) {
    throw new Error("useLeadsManagementMethod must be used within the ERProvider");
  }

  return ctx;
};