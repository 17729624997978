import React, { useState, useRef, useMemo } from 'react'
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import QRCode from 'qrcode';

//Style
import Style from "./buat-banner.module.scss"

//Components
import Button from "components/atomic/button-v2";
import Modal from 'components/modal/Modal';
import CropImage from "./CropImage"
import ModalLoader from 'components/organism/ModalLogout';

//Assets
import { ReactComponent as Arrow } from "assets/arrow-left-tail-white.svg";
import { ReactComponent as Unduh } from "assets/unduh.svg";

// Banner v1
import BgBannerTipe1x1Jual from "assets/spanduk-1x1-jual.jpg";
import BgBannerTipe1x1Sewa from "assets/spanduk-1x1-sewa.jpg";
import BgBannerTipe2x1Jual from "assets/spanduk-2x1-jual.jpg";
import BgBannerTipe2x1Sewa from "assets/spanduk-2x1-sewa.jpg";
import BgBannerTipe3x1Jual from "assets/spanduk-3x1-jual.jpg";
import BgBannerTipe3x1Sewa from "assets/spanduk-3x1-sewa.jpg";
import BgBannerTipe3x15Jual from "assets/spanduk-3x15-jual.jpg";
import BgBannerTipe3x15Sewa from "assets/spanduk-3x15-sewa.jpg";
import BgBannerTipe4x1Jual from "assets/spanduk-4x1-jual.jpg";
import BgBannerTipe4x1sewa from "assets/spanduk-4x1-sewa.jpg";

// Banner V2
import BgBannerTipe1x1Jualv2 from "assets/spanduk-1x1-jual-v2.jpg";
import BgBannerTipe1x1Sewav2 from "assets/spanduk-1x1-sewa-v2.jpg";
import BgBannerTipe2x1Jualv2 from "assets/spanduk-2x1-jual-v2.jpg";
import BgBannerTipe2x1Sewav2 from "assets/spanduk-2x1-sewa-v2.jpg";
import BgBannerTipe3x1Jualv2 from "assets/spanduk-3x1-jual-v2.jpg";
import BgBannerTipe3x1Sewav2 from "assets/spanduk-3x1-sewa-v2.jpg";
import BgBannerTipe3x15Jualv2 from "assets/spanduk-3x15-jual-v2.jpg";
import BgBannerTipe3x15Sewav2 from "assets/spanduk-3x15-sewa-v2.jpg";
import BgBannerTipe4x1Jualv2 from "assets/spanduk-4x1-jual-v2.jpg";
import BgBannerTipe4x1sewav2 from "assets/spanduk-4x1-sewa-v2.jpg";

// Banner v3
import BgBannerNoQRTipe3x1Jual from "assets/spanduk-no-qr-3x1-jual.jpg";
import BgBannerNoQRTipe3x1Sewa from "assets/spanduk-no-qr-3x1-sewa.jpg";
import BgBannerNoQRTipe3x1JualSewa from "assets/spanduk-no-qr-3x1-jual-sewa.jpg";
import BgBannerNoQRTipe1x1Jual from "assets/spanduk-no-qr-1x1-jual.jpg";
import BgBannerNoQRTipe1x1Sewa from "assets/spanduk-no-qr-1x1-sewa.jpg";
import BgBannerNoQRTipe2x1Jual from "assets/spanduk-no-qr-2x1-jual.jpg";
import BgBannerNoQRTipe2x1Sewa from "assets/spanduk-no-qr-2x1-sewa.jpg";

//Context
import { useDetailListingCtx } from "../../Context"
import { useGlobalCtx } from 'contexts';

//Api
import { trackBannerDownloads } from "actions"

const LISTING_URL = process.env.REACT_APP_LISTING_URL;

const BannerList = [
  // Banner v1
  {
    name: "Banner 1meter X 1meter",
    jual: BgBannerTipe1x1Jual,
    sewa: BgBannerTipe1x1Sewa,
    width: 1000,
    height: 1000,
    widthLabel: "1 meter",
    heightLabel: "1 meter",
    bannerType: 1,
  },
  {
    name: "Banner 1meter X 1meter",
    jual: BgBannerTipe1x1Jual,
    sewa: BgBannerTipe1x1Sewa,
    width: 1000,
    height: 1000,
    widthLabel: "1 meter",
    heightLabel: "1 meter",
    bannerType: 1,
    forAgent: true
  },
  {
    name: "Banner 2meter X 1meter",
    jual: BgBannerTipe2x1Jual,
    sewa: BgBannerTipe2x1Sewa,
    width: 2000,
    height: 1000,
    widthLabel: "2 meter",
    heightLabel: "1 meter",
    bannerType: 2,
  },
  {
    name: "Banner 2meter X 1meter",
    jual: BgBannerTipe2x1Jual,
    sewa: BgBannerTipe2x1Sewa,
    width: 2000,
    height: 1000,
    widthLabel: "2 meter",
    heightLabel: "1 meter",
    bannerType: 2,
    forAgent: true
  },
  {
    name: "Banner 3meter X 1meter",
    jual: BgBannerTipe3x1Jual,
    sewa: BgBannerTipe3x1Sewa,
    width: 3000,
    height: 1000,
    widthLabel: "3 meter",
    heightLabel: "1 meter",
    bannerType: 3,
  },
  {
    name: "Banner 3meter X 1meter",
    jual: BgBannerTipe3x1Jual,
    sewa: BgBannerTipe3x1Sewa,
    width: 3000,
    height: 1000,
    widthLabel: "3 meter",
    heightLabel: "1 meter",
    bannerType: 3,
    forAgent: true
  },
  {
    name: "Banner 3meter X 1,5meter",
    jual: BgBannerTipe3x15Jual,
    sewa: BgBannerTipe3x15Sewa,
    width: 3000,
    height: 1500,
    widthLabel: "3 meter",
    heightLabel: "1,5 meter",
    bannerType: 4,
  },
  {
    name: "Banner 3meter X 1,5meter",
    jual: BgBannerTipe3x15Jual,
    sewa: BgBannerTipe3x15Sewa,
    width: 3000,
    height: 1500,
    widthLabel: "3 meter",
    heightLabel: "1,5 meter",
    bannerType: 4,
    forAgent: true
  },
  {
    name: "Banner 4meter X 1meter",
    jual: BgBannerTipe4x1Jual,
    sewa: BgBannerTipe4x1sewa,
    width: 4000,
    height: 1000,
    widthLabel: "4 meter",
    heightLabel: "1 meter",
    bannerType: 5,
  },
  {
    name: "Banner 4meter X 1meter",
    jual: BgBannerTipe4x1Jual,
    sewa: BgBannerTipe4x1sewa,
    width: 4000,
    height: 1000,
    widthLabel: "4 meter",
    heightLabel: "1 meter",
    bannerType: 5,
    forAgent: true
  },

  // // Banner V2
  // {
  //   name: "Banner 1meter X 1meter",
  //   jual: BgBannerTipe1x1Jualv2,
  //   sewa: BgBannerTipe1x1Sewav2,
  //   width: 1000,
  //   height: 1000,
  //   widthLabel: "1 meter",
  //   heightLabel: "1 meter",
  //   bannerType: "1-v2",
  // },
  // {
  //   name: "Banner 1meter X 1meter",
  //   jual: BgBannerTipe1x1Jualv2,
  //   sewa: BgBannerTipe1x1Sewav2,
  //   width: 1000,
  //   height: 1000,
  //   widthLabel: "1 meter",
  //   heightLabel: "1 meter",
  //   bannerType: "1-v2",
  //   forAgent: true
  // },
  // {
  //   name: "Banner 2meter X 1meter",
  //   jual: BgBannerTipe2x1Jualv2,
  //   sewa: BgBannerTipe2x1Sewav2,
  //   width: 2000,
  //   height: 1000,
  //   widthLabel: "2 meter",
  //   heightLabel: "1 meter",
  //   bannerType: "2-v2",
  // },
  // {
  //   name: "Banner 2meter X 1meter",
  //   jual: BgBannerTipe2x1Jualv2,
  //   sewa: BgBannerTipe2x1Sewav2,
  //   width: 2000,
  //   height: 1000,
  //   widthLabel: "2 meter",
  //   heightLabel: "1 meter",
  //   bannerType: "2-v2",
  //   forAgent: true
  // },
  // {
  //   name: "Banner 3meter X 1meter",
  //   jual: BgBannerTipe3x1Jualv2,
  //   sewa: BgBannerTipe3x1Sewav2,
  //   width: 3000,
  //   height: 1000,
  //   widthLabel: "3 meter",
  //   heightLabel: "1 meter",
  //   bannerType: "3-v2",
  // },
  // {
  //   name: "Banner 3meter X 1meter",
  //   jual: BgBannerTipe3x1Jualv2,
  //   sewa: BgBannerTipe3x1Sewav2,
  //   width: 3000,
  //   height: 1000,
  //   widthLabel: "3 meter",
  //   heightLabel: "1 meter",
  //   bannerType: "3-v2",
  //   forAgent: true
  // },
  // {
  //   name: "Banner 3meter X 1,5meter",
  //   jual: BgBannerTipe3x15Jualv2,
  //   sewa: BgBannerTipe3x15Sewav2,
  //   width: 3000,
  //   height: 1500,
  //   widthLabel: "3 meter",
  //   heightLabel: "1,5 meter",
  //   bannerType: "4-v2",
  // },
  // {
  //   name: "Banner 3meter X 1,5meter",
  //   jual: BgBannerTipe3x15Jualv2,
  //   sewa: BgBannerTipe3x15Sewav2,
  //   width: 3000,
  //   height: 1500,
  //   widthLabel: "3 meter",
  //   heightLabel: "1,5 meter",
  //   bannerType: "4-v2",
  //   forAgent: true
  // },
  // {
  //   name: "Banner 4meter X 1meter",
  //   jual: BgBannerTipe4x1Jualv2,
  //   sewa: BgBannerTipe4x1sewav2,
  //   width: 4000,
  //   height: 1000,
  //   widthLabel: "4 meter",
  //   heightLabel: "1 meter",
  //   bannerType: "5-v2",
  // },
  // {
  //   name: "Banner 4meter X 1meter",
  //   jual: BgBannerTipe4x1Jualv2,
  //   sewa: BgBannerTipe4x1sewav2,
  //   width: 4000,
  //   height: 1000,
  //   widthLabel: "4 meter",
  //   heightLabel: "1 meter",
  //   bannerType: "5-v2",
  //   forAgent: true
  // },
  
  // // Banner v3 {NO QR}
  // {
  //   name: "Banner Without QR 3meter X 1meter",
  //   jual: BgBannerNoQRTipe3x1JualSewa,
  //   sewa: BgBannerNoQRTipe3x1JualSewa,
  //   width: 3000,
  //   height: 1000,
  //   widthLabel: "3 meter",
  //   heightLabel: "1 meter",
  //   jualSewa: true,
  //   noQR: true,
  //   bannerType: 6,
  // },
  {
    name: "Banner Without QR 3meter X 1meter",
    jual: BgBannerNoQRTipe3x1Jual,
    sewa: BgBannerNoQRTipe3x1Sewa,
    width: 3000,
    height: 1000,
    widthLabel: "3 meter",
    heightLabel: "1 meter",
    // jualSewa: true,
    noQR: true,
    bannerType: 6,
  },
  {
    name: "Banner No QR 1meter X 1meter",
    jual: BgBannerNoQRTipe1x1Jual,
    sewa: BgBannerNoQRTipe1x1Sewa,
    width: 1000,
    height: 1000,
    widthLabel: "1 meter",
    heightLabel: "1 meter",
    // jualSewa: true,
    noQR: true,
    bannerType: 7,
  },
  {
    name: "Banner No QR 2meter X 1meter",
    jual: BgBannerNoQRTipe2x1Jual,
    sewa: BgBannerNoQRTipe2x1Sewa,
    width: 2000,
    height: 1000,
    widthLabel: "2 meter",
    heightLabel: "1 meter",
    bannerType: 8,
    noQR: true,
  },
]

const RenderBanner = ({
  image,
  alt,
  name,
  phone,
  companyName,
  bannerType,
  bannerRef,
  qrCodeUrl,
  profilePicture,
  noQR
}) => {
  const RenderProfilePict = () => {
    if (bannerType === 4) {
      return <CropImage
        key={bannerType}
        imgSrc={profilePicture}
        width={100}
        height={80}
        type={bannerType}
      />
    } else if (bannerType === 5) {
      return <CropImage
        key={bannerType}
        imgSrc={profilePicture}
        width={100}
        height={65}
        type={bannerType}
      />
    }
    return null
  }

  const phoneNumber = useMemo(() => {
    let formattedString = "";

    for (let i = 0; i < phone.length; i++) {
      formattedString += phone[i];

      if ((i + 1) % 4 === 0 && i !== phone.length - 1) {
        formattedString += " ";
      }
    }

    return formattedString;
  }, [phone])

  return (
    <div ref={bannerRef} className={`${Style[`banner-tipe-${bannerType}`]}`}>
      <img className={Style["banner"]} src={image} alt={alt} />
      <p className={Style["name"]}>{name}</p>
      <p className={Style["phone"]}>{phoneNumber}</p>
      <p className={Style["company-name"]}>{companyName}</p>
      {!noQR && <img className={Style["qr-code"]} src={qrCodeUrl} alt="qr-code" />}
      {RenderProfilePict()}
    </div>
  )
}

const BuatBanner = ({ showModal, setShowModal }) => {
  const htmlContentRef = useRef(null);

  const { setShowLoading } = useGlobalCtx()
  const { getValue } = useDetailListingCtx()

  const [currentBanner, setCurrentBanner] = useState(0)
  const [loading, setLoading] = useState(false)

  const bannerData = {
    type: getValue("property_type.id") === 1 ? "jual" : "sewa",
    name: getValue("user.full_name"),
    phone: getValue("user.phone"),
    profilePicture: getValue("user.user_image.online_profile.url"),
    id: getValue("user.id"),
    username: getValue("user.username"),
    companyName: getValue("user.company.name"),
    slug: getValue("slug"),
  }

  const [currentName, setCurrentName] = useState(bannerData?.name ?? "")

  const [qrCodeUrl, setQrCodeUrl] = useState("");

  const BannerTitle = useMemo(() => {
    const selectedBanner = BannerList[currentBanner]

    const type = selectedBanner.jualSewa ? "JUALSEWA" : bannerData.type.toUpperCase();
    const ukuran = `${selectedBanner.widthLabel} x ${selectedBanner.heightLabel}`
    const qr = selectedBanner.noQR ? "NO-QR" : !!BannerList[currentBanner].forAgent ? "QR Profile" : "QR Listing"

    return `${type} ${ukuran} ${qr}`
  }, [currentBanner])

  const convertToPdf = async () => {
    try {
      setShowLoading(true)
      const input = htmlContentRef.current;

      const selectedBanner = BannerList[currentBanner]

      const width = BannerList[currentBanner].width;
      const height = BannerList[currentBanner].height;

      const scale = 10;
      const canvasOptions = {
        scale: scale,
        useCORS: true,
        allowTaint: true,
        backgroundColor: null,
      };
      const pdfOptions = {
        orientation: 'l',
        unit: 'mm',
        format: [width, height],
      };

      await html2canvas(input, canvasOptions)
        .then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          const pdf = new jsPDF(pdfOptions);
          const imgWidth = pdf.internal.pageSize.getWidth();
          const imgHeight = (canvas.height * imgWidth) / canvas.width;
          pdf.addImage(imgData, 'PNG', 0, 0, width, height); // Set the size to 4 meters by 1 meter
          pdf.save(`${BannerList[currentBanner].name}.pdf`);
        });

      await handleTrackDownload({
        ukuran: `${selectedBanner.widthLabel} x ${selectedBanner.heightLabel}`,
        qr: selectedBanner.noQR ? "NO-QR" : !!BannerList[currentBanner].forAgent ? "QR Profile" : "QR Listing",
        type: selectedBanner.jualSewa ? "JUALSEWA" : bannerData.type.toUpperCase()
      })
    } catch (error) {
      console.log(error)
    } finally {
      setShowLoading(false)
    }
  };

  const generateQRCode = async () => {
    try {
      let link = `${LISTING_URL}/ma-properti?marketingId=${bannerData.id}&propertySlug=${bannerData.slug}&source=banner`

      if (!!BannerList[currentBanner].forAgent) {
        link = `${LISTING_URL}/${bannerData?.username}?id=${bannerData.id}`
      }

      const options = {
        color: {
          dark: '#000000', // QR code color
          light: '#ffffff00' // Background color (transparent in this example)
        }
      };

      const url = await QRCode.toDataURL(link, options);
      setQrCodeUrl(url);
    } catch (err) {
      console.error(err);
    }
  };

  const handleTrackDownload = async ({ ukuran, qr, type }) => {
    try {
      const payload = {
        "banner_download": {
          "banner_type": `${ukuran} ${qr} ${type}`.toUpperCase()
        }
      }
      await trackBannerDownloads(payload)
    } catch (err) {
      console.error(err);
    }
  };

  const handlePrev = () => {
    if (currentBanner === 0) return

    setCurrentBanner(currentBanner - 1)
  }

  const handleNext = () => {
    if (currentBanner === BannerList.length - 1) return

    setCurrentBanner(currentBanner + 1)
  }

  // listen to left and right arrow key
  React.useEffect(() => {
    const handleKeyDown = (e) => {
      if (!showModal) return

      if (e.keyCode === 37) {
        handlePrev()
      }

      if (e.keyCode === 39) {
        handleNext()
      }
    }

    document.addEventListener("keydown", handleKeyDown)

    return () => {
      document.removeEventListener("keydown", handleKeyDown)
    }
  }, [currentBanner, showModal])

  React.useEffect(() => {
    generateQRCode()
  }, [bannerData, currentBanner, BannerList]);

  if (loading) return <ModalLoader open={true} />
  if (!showModal) return null
  return (
    <Modal
      toggle={() => setShowModal(!showModal)}
    >
      <div className={Style["modal-wrapper"]}>
        <div className={Style["modal-header"]}>
          <button onClick={() => setShowModal(!showModal)}><Arrow /> Kembali</button>

          <div>
            <p className={Style["ukuran-banner"]}>Ukuran: <span>{BannerList[currentBanner].widthLabel}</span> X <span>{BannerList[currentBanner].heightLabel}</span></p>
            <Button
              text={"Gunakan Template Ini"}
              onClick={convertToPdf}
              Icon={Unduh}
            />
          </div>
        </div>

        <div className={Style['pagination-wrapper']}>
          <Button
            text={"Sebelumnya"}
            bg='white'
            style={{ marginRight: "24px" }}
            onClick={handlePrev}
            feedback='inactive'
          />

          <Button
            text={"Selanjutnya"}
            bg='white'
            onClick={handleNext}
            feedback='inactive'
          />
        </div>


        <div className='mb-4 py-2 px-[48px]'>
          <p className='text-base mb-[12px] text-white'>Nama Marketing</p>
          <input value={currentName} onChange={(e) => setCurrentName(e.target.value || "")} className='bg-white rounded-md py-2 px-4 w-full text-base border-[1px] border-solid border-black-rgba-0' />
        </div>

        <p className='mb-4 py-2 px-[48px] text-center text-base text-white'>{BannerTitle}</p>

        <div className={`${Style["modal-body"]} ${Style["type-" + BannerList[currentBanner].bannerType]}`}>
          <div className={`${Style["wrapper-banner"]}`}>
            <RenderBanner
              alt={BannerList[currentBanner].name}
              type={bannerData.type}
              image={BannerList[currentBanner][bannerData.type]}
              name={currentName}
              phone={(bannerData.phone && typeof bannerData.phone === "string") ? bannerData.phone.replace("+62", "0") : "-"}
              companyName={bannerData.companyName}
              bannerType={BannerList[currentBanner].bannerType}
              qrCodeUrl={qrCodeUrl}
              profilePicture={bannerData.profilePicture}
              noQR={!!BannerList[currentBanner].noQR}
              bannerRef={htmlContentRef}
            />
          </div>
        </div>

        <div className={Style["modal-footer"]}><p>{currentBanner + 1} <span>/</span> {BannerList.length}</p></div>
      </div>
    </Modal>
  )
}

const BuatBannerWrapper = () => {
  const [showModal, setShowModal] = useState(false)

  return (
    <div className={Style["wrapper"]}>
      <p className={Style["header"]}>Buat Banner untuk Listing Ini</p>
      <p className={Style["sub-header"]}>Promosikan listing secara offline sekarang bisa lebih optimal dengan banner yang bisa dicetak. Silahkan pilih template banner yang telah kami sediakan.</p>

      <Button
        text={"Pilih Template"}
        bg='black'
        width={1}
        onClick={() => setShowModal(!showModal)}
      />

      <BuatBanner showModal={showModal} setShowModal={setShowModal} />
    </div>
  )
}

export default BuatBannerWrapper
