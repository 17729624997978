import React, { useState, useMemo, useEffect } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component';

// Assets
import { ReactComponent as IconMagnifierActive } from "assets/black-magnify.svg"

// Components
import CardCalonMA from './card-calon-ma';

// Ctx
import { useTrainingCtx } from "pages/dashboard/training/useTraining"

import { useDebounce } from 'hooks/useDebounce';
import { WrapperInfiniteScroll } from 'components/atomic-v2';

const CardCalonMarketingAssociate = () => {
  const {
    listCalonMa,
    setParamsListCalonMa,
    paramsListCalonMa,
    fetchNextPage
  } = useTrainingCtx();

  const [search, setSearch] = useState(paramsListCalonMa.q)

  const allData = useMemo(() => {
    if (!listCalonMa?.pages?.length) return [];

    return listCalonMa.pages.map(p => p?.data?.data).flat()
  }, [listCalonMa]);

  const { debounceFn } = useDebounce({
    onChange: (val) => setParamsListCalonMa({ ...paramsListCalonMa, q: val })
  })

  const handleSearch = (e) => {
    const val = e.target.value || ''
    debounceFn(val)
    setSearch(val)
  }

  const handleNext = () => {
    fetchNextPage()
  }

  return (
    <div className='min-w-[310px] max-w-[310px] rounded bg-white mr-4 pr-4 border-r border-solid border-[#D8D8D9] last:mr-0 last:pr-0 last:border-0'>
      <div className='flex items-center px-4 py-2 rounded-t'>
        <span className='text-[13px] font-semibold'>Calon Marketing Associate</span>
      </div>
      <div className='h-0.5 border-b-[0.5px] border-solid border-[#D8D8D9]' />
      <div className='px-4 py-2'>
        <div className={`flex items-center w-full rounded border border-solid p-2 
          ${!allData ? "bg-[#FAFAFA] border-[#FAFAFA]" : "border-[#D8D8D9]"}
          `}>
          <IconMagnifierActive className='w-[14px] h-[14px] mr-1' />
          <input
            placeholder='Cari marketing'
            className='text-[11px] placeholder:text-[#D8D8D9]'
            onChange={handleSearch}
            value={search}
            disabled={!allData}
          />
        </div>
      </div>

      <div scrollableTarget="scrollableDiv" className='h-0.5 border-b-[0.5px] border-dashed border-[#D8D8D9]' />
      {allData?.length ? (
        <InfiniteScroll
          dataLength={allData ?? []}
          next={handleNext}
          hasMore={!!listCalonMa?.pages[listCalonMa.pages.length - 1]?.data?.data?.length}
        // loader={<p>Loading</p>}
        >
          <div className='p-4 h-[500px] overflow-y-auto hide-scrollbar'>
            {allData.map((ma) => {
              return <CardCalonMA key={`list-calon-ma-${ma?.id}`} calonMa={ma} />
            })}
          </div>
        </InfiniteScroll>
      ) : (
        <div className='p-4 h-[500px] flex items-center justify-center'>
          <p className='text-[13px] text-[#D8D8D9]'>Belum ada Calon Marketing Associate</p>
        </div>
      )}
    </div>
  )
}

export default CardCalonMarketingAssociate