import React, { useState } from 'react'

// Components
import Modal from "components/organism/modal-v2"
import Button from "components/v1/Button"

// Assets
import { ReactComponent as XIcon } from "assets/cross.svg"
import RadioBtn from "assets/radio-2.png"


const ModalTransferListing = ({ agents, onClose, isOpen, onSelect, selectedAgen, setSelectedAgen, ...props }) => {
  const handleSelectAgen = (agen) => {
    setSelectedAgen(agen?.id === selectedAgen?.id ? null : agen)
  }

  const handleSelect = () => {
    onSelect(selectedAgen)
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} {...props}>
      <div className="w-[426px] z-[42] bg-white rounded-md border-[0.5px] border-solid border-[#ECECEC] 
        shadow-[0px_1px_3px_0px_rgba(16,16,16,0.10),_0px_1px_2px_0px_rgba(16,16,16,0.06)]">

        <div className="flex justify-between px-4 py-2 border-b border-solid border-[#D8D8D9]">
          <p className='text-[17px] font-semibold'>Tampilkan leads siapa ?</p>
          <XIcon onClick={onClose} />
        </div>

        <div className='px-4 py-2'>
          <p className='text-[13px] text-[#808285] pb-1 mb-2 border-b-[0.5px] border-dashed border-[#D8D8D9]'>Pilih Marketing</p>
          <div className='pl-4 max-h-[378px] overflow-y-auto overflow-hidden'>
            {!!agents?.length && (agents.map(agen => {
              const selected = selectedAgen?.id === agen?.id
              return (
                <button onClick={() => handleSelectAgen(agen)} className='w-full flex items-center justify-between mb-4'>
                  <p className='text-[13px]'>{agen.full_name}</p>
                  {!!selected ? <img src={RadioBtn} alt='Radio btn' /> : <div className='rounded-[24px] border border-solid border-gray-0 bg-white w-4 h-4' />}
                </button>
              )
            }))}
          </div>
        </div>

        <div className={"flex items-center justify-end px-4 py-2 border-t border-solid border-[#D8D8D9]"}>
          <Button onClick={handleSelect} disabled={!selectedAgen} text="Tampilkan" />
        </div>
      </div>
    </Modal>
  )
}

const Wrapper = ({ isOpen, ...props }) => {
  if (!isOpen) return null;
  return <ModalTransferListing isOpen={isOpen} {...props} />
}

export default Wrapper
