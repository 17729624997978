import { api } from "utils";
import { PUBLIC_V1 } from "constant/URL";
import { objectToQueryParams } from "utils/objectToQueryParams";

export const tandaiMenanggapi = async (leadId) => {
  return api({}, true).put(`/${PUBLIC_V1}/lead-managements/${leadId}`)
}

export const getListManagement = async (params) => {
    const newParams = (!!params && typeof params === "object") ? `${objectToQueryParams(params)}` : ""
  return api({}, true).get(`/${PUBLIC_V1}/leads?${newParams}`)
}