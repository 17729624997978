import { createContext, useState, useEffect, useMemo } from "react";
import lodash from "lodash"
import { toast, ToastContainer } from 'react-toastify';
import { useParams } from "react-router-dom";
import imageCompression from 'browser-image-compression';
import { useNavigate } from 'react-router-dom'

import "react-toastify/dist/ReactToastify.css";

//Hooks
import { useForm, useUploadListing, useRefresh } from "../hooks";

//Actions
import { uploadListingAction, getDetailOfficeListing, getDetailImages, editListing, getAllCities } from "../actions";
import AuthAction from "actions/authAction";

//Utils
import urlToFile from "utils/urlToFile";

//Components
import ModalLoader from "components/organism/ModalLoader";

const maxPage = 1000;

export const UploadListingContext = createContext();

const renderListSpekProp = {
  "rumah": ["bedroom", "bathroom", "surface_area", "building_area"],
  "apartemen": ["bedroom", "bathroom", "building_area"],
  "ruko": ["surface_area", "building_area"],
  "tanah": ["surface_area"],
  "gudang": ["surface_area", "building_area"],
  "gedung": ["surface_area", "land_length", "land_width", "building_area", "building_length", "building_width", "parking_capacity", "number_of_phone_lines"]
}

const initForm = {
  // Marketing Associate
  agent: {
    id: null,
    full_name: ""
  },

  // Privasi Listing
  property_privacy_id: 1,

  // Jenis Listing
  property_type_id: 1,

  // Jenis Property
  property_category: {
    id: null,
    name: ""
  },

  // Nama Listing
  title: "",

  // Gambaran Umum Listing
  description: "",

  // Harga/unit
  price: "",
  unit_price_id: {
    id: null,
    name: ""
  },

  // Komisi penjualan
  commission_rate: "",
  commission_amount: "",

  // Split komisi listing
  commission_listing_rate: "",
  commission_listing_amount: "",

  // Split komisi selling
  commission_selling_rate: "",
  commission_selling_amount: "",

  // Nama & whatsapp owner 
  property_owner_attributesname: "",
  property_owner_attributesphone: "",

  // Status kepemilikan
  ownership_status: {
    id: 1,
    name: ""
  },

  // Catatan
  note: "",

  // Luas Tanah
  surface_area: null,

  // Panjang Tanah
  land_length: null,

  // Lebar Tanah
  land_width: null,

  // Luas Bangunan
  building_area: null,

  // Panjang Bangunan
  building_length: null,

  // Lebar Bangunan
  building_width: null,

  // Kapasitas Garasi
  garage_capacity: null,

  // Kapasitas Parkir
  parking_capacity: null,

  // Jumlah Lantai
  number_of_floors: null,

  // Kamar Tidur
  bedroom: null,
  additional_bedroom: null,

  // Kamar Mandi
  bathroom: null,
  additional_bathroom: null,

  // durasi sewa
  min_rent: "",
  // Listrik
  electrical_power: null,

  // Jumlah Line Telepon
  number_of_phone_lines: null,

  // Upload Gambar
  property_images_attributes: [],

  country_id: 1,
  areas: {
    id: null,
    fullName: ""
  },
  unpublish_map_street: "",
  publish_map_street: "",
  unpublish_map: {
    street: "",
    latitude: 0,
    longitude: 0,
  },
  publish_map: {
    street: "",
    latitude: 0,
    longitude: 0,
  },
  year: null,
  property_condition: {
    id: null,
    name: ""
  },
  furniture_condition: {
    id: null,
    name: ""
  },
  face: {
    id: null,
    name: ""
  },
  fasilitasRumah: {},
  fasilitasSekitarRumah: {},
}

export const UploadListingProvider = ({ children }) => {
  const navigate = useNavigate()
  const { slug } = useParams();
  const [auth, setAuth] = useState(null);

  const isMB = useMemo(() => {
    return lodash.get(auth, "role.name") == "Member Broker"
  }, [auth])

  // Form hooks
  const {
    form,
    onChangeForm,
    onSelectForm,
    manualSelectForm,
    formValidation,
    setForm,
  } = useForm({ ...initForm })

  const [_, setShowExitPrompt] = useRefresh()

  const defaultSelectStudio = +form.bedroom === 0 && +form.bathroom === 0;
  const [selectedStudio, setSelectedStudio] = useState(defaultSelectStudio);
  const isApartmentStudio = +form.property_category?.id === 2 && selectedStudio;

  // Actions
  const {
    marketingAgent,
    propertyTypes,
    propertyCategories,
    ownerShipIds,
    dataFasilitasPribadi,
    dataFasilitasSekitar,
    initializer,
    uploadListing,
    loadingMarketingAgent
  } = useUploadListing()

  // UseState
  const [process, setProcess] = useState("identitas-listing")
  const [allFormEmpty, setAllFormEmpty] = useState(false)
  const [score, setScore] = useState(5)

  const [savedIdentitasListing, setSavedIdentitasListing] = useState(false);
  const [savedSpekProp, setSavedProp] = useState(false);
  const [savedGambar, setSavedGambar] = useState(false);
  const [startLoading, setStartLoading] = useState(false);
  const [submitError, setSubmitError] = useState(false);
  const [successUploadListing, setSuccessUploadListing] = useState(false);

  const isTanah = form.property_category.name == "Tanah"

  // upload gambar
  const [files, setFiles] = useState([])
  const [selectedVideo, setSelectedVideo] = useState(null)

  // fasilitas - pribadi
  const [fasilitasPribadi, setFasilitasPribadi] = useState([])

  // fasilitas - sekitar
  const [fasilitasSekitar, setFasilitasSekitar] = useState([])

  const [fetchingListingDetail, setFetchingListingDetail] = useState(slug ? true : false)

  const [isToggleLMS, setIsToggleLMS] = useState(true);

  //unit price id
  const [unitPrice, setUnitPrice] = useState({
    fetching: false,
    data: [],
    error: null
  })

  /** EDIT - original data */
  const [bakcupNestedAttributesData, setBackupNestedAttributesData] = useState({
    fasilitasPribadi: [],
    fasilitasSekitar: [],
    files: [],
    locations: [],
    owner_attributes: {
      id: null
    }
  })

  const isHaveUnitPrice = form.property_category.id === 13 || form.property_type_id === 2 || (form.property_category.id === 4 || form.property_category.id === 12)

  //Handlers
  const isAllFormEmpty = () => {
    const newForm = { ...form }
    delete newForm.property_privacy_id
    delete newForm.property_type_id
    delete newForm.agent
    delete newForm.country_id

    let isEmpty = true

    for (let key in newForm) {
      const isObject = typeof newForm[key] == "object";
      const isArray = Array.isArray(newForm[key])
      if (key == "ownership_status") {
        continue
      }
      if (isObject && !isArray) {
        if (newForm[key]?.id != null) {
          isEmpty = false
          break;
        }
      }

      if (Array.isArray(newForm[key])) {
        if (newForm[key].length) {
          isEmpty = false
          break;
        }
      }
      if (newForm[key] && !isObject && !isArray) {
        isEmpty = false
        break;
      }
    }

    setShowExitPrompt(!isEmpty)
    setAllFormEmpty(isEmpty)
  }

  const getUserProfile = async () => {
    try {

      const list = await AuthAction.getUserDetail()
      const newData = list?.data ?? {}

      setAuth(newData)
    } catch (error) {
      console.log(error)
    }
  }

  const handleCompresedFile = (files) => new Promise(resolve => {
    (async () => {

      await Promise.all(files.map(async (f) => {
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true
        }

        const compressedFile = await imageCompression(f.file, options);
        const newFile = new File([compressedFile], compressedFile.name, { type: 'image/png' });

        return { ...f, file: newFile }
      })).then(res => {
        resolve(res)
      })
    })();
  })

  //Identitas listing
  const selectMap = (name1, name2, value) => {
    const newFrom = { ...form }
    let obj1 = newFrom[name1] || {}
    newFrom[name1] = { ...obj1, ...value }
    newFrom[name2] = value?.street ?? ""

    setForm(newFrom)
  }

  const onChangeStreet = ({ name, value }) => {
    const newFrom = { ...form }
    newFrom[name].street = value

    setForm(newFrom)
  }

  const onChangeKP = (e) => {
    const { name, value } = e.target

    const newForm = { ...form }

    let commission_amount = !newForm.price ? "" : +newForm.price * (+value / 100)

    newForm[name] = value
    newForm.commission_amount = commission_amount

    //Reset split komission listing
    newForm.commission_listing_rate = 50
    newForm.commission_listing_amount = !commission_amount ? 0 : commission_amount / 2

    // Reset split komission selling
    newForm.commission_selling_rate = 50
    newForm.commission_selling_amount = !commission_amount ? 0 : commission_amount / 2

    setForm(newForm)
  }

  const onChangeSKL = (e) => {
    const { name, value } = e.target

    const newForm = { ...form }
    newForm[name] = value

    if (!newForm.commission_amount) {
      setForm(newForm)
      return
    }

    let commission_listing_amount = +newForm.commission_amount * (+value / 100)

    newForm.commission_listing_amount = commission_listing_amount

    // Reset split komission selling
    newForm.commission_selling_rate = 100 - +value
    newForm.commission_selling_amount = +newForm.commission_amount * (+newForm.commission_selling_rate / 100)

    setForm(newForm)
  }

  const resetSpekProps = (_form) => {
    const newFrom = {
      ..._form,
      surface_area: null,
      land_length: null,
      land_width: null,
      building_area: null,
      building_length: null,
      building_width: null,
      garage_capacity: null,
      parking_capacity: null,
      number_of_floors: null,
      bedroom: null,
      bathroom: null,
      electrical_power: null,
      number_of_phone_lines: null,
      additional_bathroom: null,
      additional_bedroom: null,
      property_condition: {
        id: null,
        name: ""
      },
      furniture_condition: {
        id: null,
        name: ""
      },
      face: {
        id: null,
        name: ""
      },
      fasilitasRumah: {

      },
      fasilitasSekitarRumah: {

      },

    }
    setFasilitasPribadi([])
    setFasilitasSekitar([])
    return newFrom
  }

  const descriptionValidation = (text) => {
    if (!text) return false;

    const descriptionRaw = text;
    const regexPhoneNumberExist = /(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([0-9]1[02-9]|[0-9][02-8]1|[0-9][02-8][02-9])\s*\)|([0-9]1[02-9]|[0-9][02-8]1|[0-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([0-9]1[02-9]|[0-9][02-9]1|[0-9][02-9]{1})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?/img;
    const testPhone = regexPhoneNumberExist.test(descriptionRaw.replace(/[^\w\s]/gi, ""));
    const regexSpecialChar = /[*$|@#~“?<>{}¥£]{1,}/g;
    const testSpecialChar = regexSpecialChar.test(descriptionRaw);
    const regexSpecialCharCommaDot = /[.,’!%]{2,}/g; // test more than 1 sequential string
    const testregexSpecialCharCommaDot = regexSpecialCharCommaDot.test(descriptionRaw);
    const check6DigitOrMore = /\d{6,}/;
    const isBelow6Digit = !check6DigitOrMore.test(text);

    return !testPhone && !testSpecialChar && !testregexSpecialCharCommaDot && text.length >= 20 && isBelow6Digit
  }

  const identitasListingValidation = () => {
    try {
      const keys = [
        "agent",
        "property_privacy_id",
        "property_type_id",
        "property_category",
        "title",
        "price",
        "areas",
        "ownership_status"
      ]

      for (let i = 0; i < keys.length; i++) {
        if (typeof form[keys[i]] == "object") {
          if (!form[keys[i]].id) {
            return false
          }
        } else {
          if (!form[keys[i]]) {
            return false
          }
        }
      }

      //manual check
      if (form.title.length < 40) return false
      if (+form.commission_rate === 0) return false
      if (!descriptionValidation(form?.description)) return false;
      if (!form.unpublish_map.street) return false
      if (!form.publish_map.street) return false
      if (!+form.publish_map.latitude) return false
      if (!+form.unpublish_map.latitude) return false
      if(isHaveUnitPrice && !form.unit_price_id.id) return false

      return true
    } catch (error) {
      console.log(error)
    }
  }

  const saveIdentitasListing = () => {
    setSavedIdentitasListing(true)
    setProcess("spesifikasi-property")
  }

  //Spesifikasi property
  const isSpekPropFullfilled = (list) => {
    let result = true
    list.forEach(l => {
      if (typeof form[l] == "object" && !form[l]) {
        if (!form[l]?.id) {
          result = false
        }
      } else {
        if (!form[l]) {
          result = false
        }
      }
    })

    return result
  }
  const spesifikasiPropertyValidation = () => {
    try {
      if (!propertyCategories.length) return ""

      const category = propertyCategories.filter((cat) => cat.id == form.property_category.id)
      if (!category.length) return ""

      switch (category[0].name) {
        case "Rumah": {
          const RUMAH = ["surface_area", "building_area", "bedroom", "bathroom", "property_condition"]
          return isSpekPropFullfilled(RUMAH)
        }
        case "Apartment": {
          if (isApartmentStudio) {
            const _APARTMENT = ["building_area", "property_condition"]
            return isSpekPropFullfilled(_APARTMENT)
          }
          const APARTMENT = ["building_area", "bedroom", "bathroom", "property_condition"]
          return isSpekPropFullfilled(APARTMENT)
        }
        case "Ruko": {
          const RUKO = ["surface_area", "building_area", "property_condition"]
          return isSpekPropFullfilled(RUKO)
        }
        case "Tanah": {
          const TANAH = ["surface_area"]
          return isSpekPropFullfilled(TANAH)
        }
        case "Gudang": {
          const GUDANG = ["surface_area", "building_area", "property_condition"]
          return isSpekPropFullfilled(GUDANG)
        }
        case "Gedung": {
          const GEDUNG = ["surface_area", "building_area", "property_condition"]
          return isSpekPropFullfilled(GEDUNG)
        }
        case "Hotel": {
          const HOTEL = ["surface_area", "building_area", "bedroom", "bathroom", "property_condition"]
          return isSpekPropFullfilled(HOTEL)
        }
        case "Kios": {
          const KIOS = ["surface_area", "building_area", "bedroom", "bathroom", "property_condition"]
          return isSpekPropFullfilled(KIOS)
        }
        case "Kos": {
          const KOS = ["surface_area", "building_area", "bedroom", "bathroom", "property_condition"]
          return isSpekPropFullfilled(KOS)
        }
        case "Pabrik / Industri": {
          const PARBRIKINDUSTRI = ["surface_area", "building_area", "property_condition"]
          return isSpekPropFullfilled(PARBRIKINDUSTRI)
        }
        case "Rumah Kantor": {
          const RUMAHKANTOR = ["surface_area", "building_area", "bedroom", "bathroom", "property_condition"]
          return isSpekPropFullfilled(RUMAHKANTOR)
        }
        case "Space Kantor": {
          const RUMAHKANTOR = ["building_area", "bathroom", "property_condition"]
          return isSpekPropFullfilled(RUMAHKANTOR)
        }
        default: return false
      }
    } catch (error) {
      console.log(error)
      return false
    }
  }

  const saveSpesifikasiProperty = () => {
    setSavedProp(true)
    setProcess("upload-gambar")
  }

  const scoreHandler = () => {
    let newScore = form.property_category.name === "Gudang" ? 8 : 0

    if (form.property_category.name === "Tanah") {
      form?.property_type_id && (newScore += 7.5)
      form?.property_category?.id && (newScore += 7.5)
      form?.title?.length >= 40 && (newScore += 20)
      form?.ownership_status?.id && (newScore += 15)


      if (form.areas.id && form.unpublish_map.street) {
        newScore += 7.5
      }
      if (form.areas.id && form.publish_map.street) {
        newScore += 7.5
      }

      if (files.length) {
        if (files.length >= 4) {
          newScore += 4
        }
        if (files.length >= 5 && files.length <= 10) {
          newScore += (files.length - 4) * 4
        }

        let tagScore = {};
        files.forEach(f => {
          const _tag = f?.tag?.name ?? ""
          if (_tag && _tag === "Tampak depan" && !tagScore[_tag]) {
            tagScore[_tag] = 1
          }
        })

        newScore += Object.keys(tagScore).length * 7.5
      }
    } else {
      form?.property_type_id && (newScore += 5)
      form?.property_category?.id && (newScore += 5)
      form?.title?.length >= 40 && (newScore += 10)
      form?.ownership_status?.id && (newScore += 6)
      form?.property_condition?.id && (newScore += 6)
      form?.bedroom && (newScore += 6)
      form?.bathroom && (newScore += 6)

      fasilitasPribadi.length && (newScore += 6)
      fasilitasSekitar.length && (newScore += 6)

      let skorFasilitasRumah = false
      for (let i in form.fasilitasRumah) {
        if (form.fasilitasRumah[i]?.name) {
          skorFasilitasRumah = true
        }
      }
      skorFasilitasRumah && (newScore += 6)

      let skorFasilitasSekitarRumah = false
      for (let i in form.fasilitasRumah) {
        if (form.fasilitasRumah[i]?.name) {
          skorFasilitasSekitarRumah = true
        }
      }
      skorFasilitasSekitarRumah && (newScore += 6)


      if (form.areas.id && form.unpublish_map.street) {
        newScore += 5
      }
      if (form.areas.id && form.publish_map.street) {
        newScore += 5
      }

      if (files.length) {
        if (files.length >= 4) {
          newScore += 5
        }
        if (files.length >= 5 && files.length <= 10) {
          newScore += (files.length - 4) * 5
        }

        let tagScore = {};
        files.forEach(f => {
          const _tag = f?.tag?.name ?? ""
          if (_tag && !tagScore[_tag]) {
            tagScore[_tag] = 1
          }
        })

        newScore += Object.keys(tagScore).length * 2.5
      }
    }

    setScore(newScore >= 100 ? 100 : newScore)
  }

  const saveUploadGambar = () => {
    setSavedGambar(true)
  }

  const handleMinRent = (propertyTypeId, minRentId) => {
    if (propertyTypeId == 1) return null;

    switch (minRentId) {
      case 2: return 365
      case 3: return 30
      case 4: return 7
      case 5: return 1
    }
  }

  const handleCommisionAmount = (value) => {
    let result = value;
    if (form.min_rent.name == "Per M2") {
      result = value * form.surface_area
    }

    return result;
  }


  const FormulatePayload = async () => {
    const newFiles = await handleCompresedFile(files)

    return {
      property_privacy_id: form.property_privacy_id,
      property_category_id: form.property_category?.id,
      property_type_id: form.property_type_id,
      unit_price_id: form.unit_price_id?.id || 1,
      ownership_status_id: form.ownership_status?.id,
      title: form.title,
      description: form.description,
      price: form.price,
      commission_rate: form.commission_rate || 0,
      commission_amount: handleCommisionAmount(form.commission_amount),
      note: form.note,
      bedroom: isApartmentStudio ? 0 : form.bedroom,
      bathroom: isApartmentStudio ? 0 : form.bathroom,
      surface_area: form.surface_area,
      land_length: form.land_length,
      land_width: form.land_width,
      building_area: form.building_area,
      building_length: form.building_length,
      building_width: form.building_width,
      number_of_floors: isApartmentStudio ? 0 : form.number_of_floors,
      electrical_power: lodash.toNumber(form.electrical_power),
      garage_capacity: form.garage_capacity,
      parking_capacity: form.parking_capacity,
      number_of_phone_lines: form.number_of_phone_lines,
      property_owner_attributes_name: form.property_owner_attributesname,
      property_owner_attributes_phone: form.property_owner_attributesphone,
      user_id: form.agent?.id,
      min_rent: handleMinRent(form.property_type_id, form.unit_price_id?.id),
      property_locations: [
        {
          area: form.areas.id,
          street: form.publish_map.street,
          latitude: form.publish_map.latitude,
          longitude: form.publish_map.longitude,
          is_publish: true,
          id: form?.publish_map?.id,
        },
        {
          area: form.areas.id,
          street: form.unpublish_map.street,
          latitude: form.unpublish_map.latitude,
          longitude: form.unpublish_map.longitude,
          is_publish: false,
          id: form?.unpublish_map?.id,
        }
      ],
      property_images: newFiles,
      year: form?.year ?? null,
      property_condition: form?.property_condition?.name ?? null,
      furniture_condition: form?.furniture_condition?.name ?? null,
      additional_bedroom: isApartmentStudio ? 0 : !!form?.additional_bedroom ? form?.additional_bedroom : null,
      additional_bathroom: isApartmentStudio ? 0 : !!form?.additional_bathroom ? form?.additional_bedroom : null,
      face: form?.face?.name ?? null,
      score,
      property_facilities_attributes: fasilitasPribadi?.length ? fasilitasPribadi : [],
      property_environment_facilities_attributes: fasilitasSekitar?.length ? fasilitasSekitar : [],
      share_to_arebi: isToggleLMS
    }
  }

  const payloadToFormData = (payload) => {
    const formData = new FormData()

    for (let key in payload) {
      if (key === "video") {
        formData.append(`property[${key}]`, payload[key] || "")
        continue
      }

      if (key === "share_to_arebi") {
        formData.append(`property[share_to_arebi]`, payload[key] ?? false)
        continue
      }

      if (isApartmentStudio) {
        if (key === "number_of_floors" || key === "bedroom" || key === "bathroom" || key === "additional_bedroom" || key === "additional_bathroom") {
          formData.append(`property[${key}]`, 0)
          continue
        }
      }

      if (!payload[key]) {
        continue
      }

      if (key == "property_images") {
        payload[key].forEach(async (img, idx) => {
          img.id && formData.append(`property[property_images_attributes[${idx}][id]]`, img.id)
          formData.append(`property[property_images_attributes[${idx}][image]]`, img.file)
          formData.append(`property[property_images_attributes[${idx}][is_360]]`, false)
          formData.append(`property[property_images_attributes[${idx}][position]]`, img.position)
          if (img?.name || img?.tag?.name) {
            formData.append(`property[property_images_attributes[${idx}][name]]`, img?.name || img.tag.name)
          }
        })

        if (slug) {
          const BakFiles = bakcupNestedAttributesData.files;
          const imagesId = lodash.compact(payload.property_images.map(p => p.id))
          const propsImgLen = payload?.property_images?.length ?? 0;
          BakFiles.forEach((f, k) => {
            if (!imagesId.includes(f.id)) {
              formData.append(`property[property_images_attributes[${propsImgLen + (k + 1)}][id]]`, f.id)
              formData.append(`property[property_images_attributes[${propsImgLen + (k + 1)}][_destroy]]`, f.id)
            }
          })
        }

        continue;
      }


      if (key == "property_facilities_attributes") {
        payload[key].forEach((facility, idx) => {
          facility?.facility_id && formData.append(`property[property_facilities_attributes[${idx}][facility_id]]`, facility?.facility_id)
          facility?.id && formData.append(`property[property_facilities_attributes[${idx}][id]]`, facility?.id)
        })

        if (slug) {
          const BakFiles = bakcupNestedAttributesData.fasilitasPribadi;
          const facilitiesIds = lodash.compact(payload?.property_facilities_attributes.map(p => p.id))
          const propsFacilityLen = payload?.property_facilities_attributes?.length ?? 0;
          BakFiles.forEach((f, k) => {
            if (!facilitiesIds.includes(f.id)) {
              formData.append(`property[property_facilities_attributes[${propsFacilityLen + (k + 1)}][id]]`, f.id)
              formData.append(`property[property_facilities_attributes[${propsFacilityLen + (k + 1)}][_destroy]]`, f.id)
            }
          })
        }
        continue;
      }

      if (key == "property_environment_facilities_attributes") {
        payload[key].forEach((facility, idx) => {
          facility.facility_id && formData.append(`property[property_environment_facilities_attributes[${idx}][facility_id]]`, facility.facility_id)
          facility.id && formData.append(`property[property_environment_facilities_attributes[${idx}][id]]`, facility.id)
        })

        if (slug) {
          const BakFiles = bakcupNestedAttributesData.fasilitasSekitar;
          const facilitiesIds = lodash.compact(payload?.property_environment_facilities_attributes.map(p => p.id))
          const propsFacilityLen = payload?.property_environment_facilities_attributes?.length ?? 0;

          BakFiles.forEach((f, k) => {
            if (!facilitiesIds.includes(f.id)) {
              formData.append(`property[property_environment_facilities_attributes[${propsFacilityLen + (k + 1)}][id]]`, f.id)
              formData.append(`property[property_environment_facilities_attributes[${propsFacilityLen + (k + 1)}][_destroy]]`, f.id)
            }
          })
        }

        continue;
      }

      if (key == "property_owner_attributes_name") {
        formData.append(`property[property_owner_attributes[name]]`, payload[key] || null)
        formData.append(`property[property_owner_attributes[id]]`, bakcupNestedAttributesData?.owner_attributes?.id)

        continue;
      }

      if (key == "property_owner_attributes_phone") {
        formData.append(`property[property_owner_attributes[phone]]`, payload[key] || null)

        continue;
      }

      if (key == "property_locations") {
        payload[key].forEach((loc, idx) => {
          loc?.id && formData.append(`property[property_locations_attributes[${idx}][id]]`, loc.id)
          formData.append(`property[property_locations_attributes[${idx}][country_id]]`, 1)
          formData.append(`property[property_locations_attributes[${idx}][area_id]]`, loc.area)
          formData.append(`property[property_locations_attributes[${idx}][street]]`, loc.street)
          formData.append(`property[property_locations_attributes[${idx}][latitude]]`, loc.latitude)
          formData.append(`property[property_locations_attributes[${idx}][longitude]]`, loc.longitude)
          formData.append(`property[property_locations_attributes[${idx}][is_publish]]`, loc.is_publish)
        })

        continue;
      }

      formData.append(`property[${key}]`, payload[key] || "")
    }

    if (slug && bakcupNestedAttributesData.locations.length > 2) {
      const ids = bakcupNestedAttributesData.locations.filter(i => i.id != form.publish_map.id && i.id != form.unpublish_map.id).map(i => i.id);

      ids.forEach((id, idx) => {
        formData.append(`property[property_locations_attributes[${idx + 2}][id]]`, id)
        formData.append(`property[property_locations_attributes[${idx + 2}][_destroy]]`, true)
      })
    }

    return formData
  }
  const UploadListingHandler = async () => {
    try {
      setSavedGambar(true)
      setStartLoading(true)
      setSubmitError(false)
      FormulatePayload().then(async (res) => {
        const formData = payloadToFormData(res)

        if (slug) {
          await editListing(slug, formData)
            .then(res => {
              setForm({ ...initForm })
              setShowExitPrompt(false)
              setSuccessUploadListing(true)
            })
            .catch(err => {
              setSubmitError(true)
              toast.error(`Gagal menyimpan! \n ${JSON.stringify(err, null, 2)}`, {
                position: toast.POSITION.TOP_CENTER
              });
            })
        } else {
          await uploadListing(form.property_type_id, formData, (resolve, error) => {
            if (resolve) {
              setForm({ ...initForm })
              setShowExitPrompt(false)
              setSuccessUploadListing(true)
            } else {
              setSubmitError(true)
              toast.error(`Gagal menyimpan! \n ${JSON.stringify(error, null, 2)}`, {
                position: toast.POSITION.TOP_CENTER
              });
            }
          })
        }
      })
    } catch (error) {
      console.log(error)
      setSubmitError(true)
      toast.error("Gagal menyimpan!", {
        position: toast.POSITION.TOP_CENTER
      });
    } finally {
      setTimeout(() => {
        setSavedGambar(false)
        setStartLoading(false)
      }, 3000)
    }
  }
  const confirmSuccessSubmit = () => {
    if (isMB) {
      navigate("/ereporting")
    } else {
      navigate("/")
    }
  }

  const getUnitPriceId = async (id) => {
    try {
      setUnitPrice(val => ({ ...val, fetching: true, error: null }))
      await uploadListingAction.getUnitPriceId(`?property_type_id=${id}`)
        .then(res => {
          setUnitPrice(val => ({ ...val, data: res?.data ?? [] }))
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          setUnitPrice(val => ({ ...val, fetching: false }))
        })

    } catch (error) {
      console.log(error)
    }
  }

  //Get details
  const getDetailPropertiLocation = (locations, publish) => {
    let result = {
      area: {
        id: null,
        fullName: ""
      },
      street: "",
      latitude: "",
      longitude: ""
    }
    if (!locations?.length) return result;

    const _location = locations.filter((loc) => loc.is_publish == publish)

    if (!_location?.length && _location[0]) return result;
    result = { ..._location[0] }
    return result;
  }
  const mappingValueToForm = async (listing, images) => {

    const getObjVal = (str) => lodash.get(listing, str)

    const newForm = { ...form };
    newForm.agent = getObjVal("user") || {};
    newForm.property_privacy_id = getObjVal("property_privacy.id");
    newForm.property_type_id = getObjVal("property_type.id");
    newForm.property_category = getObjVal("property_category");
    newForm.title = getObjVal("title");
    newForm.description = getObjVal("description");
    newForm.price = getObjVal("price");
    newForm.unit_price_id = {
      id: getObjVal("unit_price.id")
    };
    newForm.commission_rate = getObjVal("commission_rate");
    newForm.commission_amount = getObjVal("commission_amount");
    newForm.ownership_status = getObjVal("ownership_status");
    newForm.year = getObjVal("year");
    newForm.land_width = getObjVal("land_width");
    newForm.land_length = getObjVal("land_length");
    newForm.surface_area = getObjVal("surface_area");
    newForm.building_area = getObjVal("building_area");
    newForm.building_length = getObjVal("building_length");
    newForm.building_width = getObjVal("building_width");
    newForm.number_of_floors = getObjVal("number_of_floors");
    newForm.bathroom = getObjVal("bathroom");
    newForm.additional_bathroom = getObjVal("additional_bathroom");
    newForm.bedroom = getObjVal("bedroom");
    newForm.additional_bedroom = getObjVal("additional_bedroom");
    newForm.electrical_power = getObjVal("electrical_power");
    newForm.garage_capacity = getObjVal("garage_capacity");
    newForm.parking_capacity = getObjVal("parking_capacity");
    newForm.face = { name: getObjVal("face"), id: null };
    newForm.furniture_condition = { name: getObjVal("furniture_condition"), id: null };
    newForm.property_condition = { name: getObjVal("property_condition"), id: null };
    newForm.property_owner_attributesname = getObjVal("property_owner.name");
    newForm.property_owner_attributesphone = getObjVal("property_owner.phone");


    //Locations
    const locations = getObjVal("summary.property_locations")
    const { city, area, street, longitude, latitude, id: publishId } = getDetailPropertiLocation(locations, true)
    const { street: unpublish_map_street, id: unpublishId } = getDetailPropertiLocation(locations, false)
    newForm.areas = { ...area, fullName: `${area?.name}, ${city?.name}` }
    newForm.unpublish_map_street = unpublish_map_street
    newForm.publish_map_street = street
    newForm.publish_map = {
      street,
      latitude,
      longitude,
      id: publishId,
    }
    newForm.unpublish_map = {
      street: unpublish_map_street,
      latitude,
      longitude,
      id: unpublishId
    }

    const _fasilitasPribadi = getObjVal("facilities") || [];
    const _fasilitasSekitar = getObjVal("environment_facilities") || [];

    const newImages = await Promise.all(await images.map(async (img) => {
      const result = await urlToFile(img?.image?.thumbnail?.url || img?.image?.url)
      const newFiles = { ...img, file: result }
      return newFiles
    }))
      .then(res => res)

    const videoUrl = getObjVal("video.url");

    async function createFileFromUrl(url) {
      try {
        const response = await fetch(url);
        const blob = await response.blob();

        // Create a File object with properties
        const fileName = 'video.mp4'; // You can set the desired file name here
        const file = new File([blob], fileName, {
          type: 'video/mp4',
          lastModified: new Date(),
        });

        return file;
      } catch (error) {
        console.error('Error fetching the file:', error);
        return null;
      }
    }

    const newVideo = !!videoUrl && await createFileFromUrl(videoUrl).then((file) => {
      if (file) {
        return file
      } else {
        return null
      }
    });

    let sharedToArebiVal = getObjVal("share_to_arebi")

    setIsToggleLMS(sharedToArebiVal)

    setFetchingListingDetail(false)
    setForm(newForm)
    setFasilitasPribadi(_fasilitasPribadi)
    setFasilitasSekitar(_fasilitasSekitar)
    setScore(getObjVal("score"))
    setFiles(newImages || [])
    setSelectedVideo(newVideo)
    setBackupNestedAttributesData({
      fasilitasPribadi: _fasilitasPribadi || [],
      fasilitasSekitar: _fasilitasSekitar || [],
      files: newImages || [],
      locations: locations,
      owner_attributes: getObjVal("property_owner")
    })
  }
  const getListingDetail = async () => {
    try {
      setFetchingListingDetail(true)
      const detail = await getDetailOfficeListing(slug)

      const images = await getDetailImages(lodash.get(detail?.data, "links.property_images"))
      if (detail?.data) {
        mappingValueToForm(detail.data, images?.data || [])
      } else {
        setFetchingListingDetail(false)
      }
    } catch (error) {
      console.log(error)
      setFetchingListingDetail(false)
    }
  }

  useEffect(() => {
    if (slug) {
      getListingDetail()
    }
  }, [slug])

  //Use effects
  useEffect(() => {
    isAllFormEmpty()
  }, [form])

  useEffect(() => {

    form.property_type_id && getUnitPriceId(form.property_type_id)
    if (!fetchingListingDetail && !slug) {
      const newForm = resetSpekProps(form)

      if (form.property_type_id === 2) {
        newForm.min_rent = {
          "id": 2,
          "name": "Unit / Tahun",
          "links": {
            "self": "/public/v1/unit-prices/2"
          }
        }
      } else {
        newForm.min_rent = {
          "id": 1,
          "name": "Per Unit",
          "links": {
            "self": "/public/v1/unit-prices/7"
          }
        }
      }

      setForm(newForm)
    }
  }, [form.property_type_id, form.property_category.id, fetchingListingDetail])

  useEffect(() => {
    scoreHandler()
  }, [
    form,
    files,
    fasilitasPribadi,
    fasilitasSekitar
  ])

  useEffect(() => {
    if (form.property_category?.id === 2) {
      setSelectedStudio(+form.bedroom === 0 && +form.bathroom === 0 || form.bedroom === "Studio")
    }
  }, [form.bedroom, form.bathroom, form.property_category])

  useEffect(() => {
    getUserProfile()
  }, [])

  if (fetchingListingDetail) return <ModalLoader open={true} />;
  return (
    <UploadListingContext.Provider value={{
      form,
      process,
      allFormEmpty,
      propertyCategories,
      marketingAgent,
      propertyTypes,
      ownerShipIds,
      savedIdentitasListing,
      savedSpekProp,
      savedGambar,
      files,
      renderListSpekProp,
      successUploadListing,
      unitPrice,
      startLoading,
      submitError,
      score,
      isTanah,
      fasilitasPribadi,
      fasilitasSekitar,
      dataFasilitasPribadi,
      dataFasilitasSekitar,
      initializer,
      slug,
      isMB,
      selectedVideo,
      loadingMarketingAgent,
      isToggleLMS,
      selectedStudio,
      isHaveUnitPrice,
      setIsToggleLMS,
      setSelectedVideo,
      setFasilitasSekitar,
      setFasilitasPribadi,
      setScore,
      uploadListing,
      setProcess,
      onSelectForm,
      manualSelectForm,
      onChangeForm,
      formValidation,
      isAllFormEmpty,
      identitasListingValidation,
      saveIdentitasListing,
      spesifikasiPropertyValidation,
      saveSpesifikasiProperty,
      saveUploadGambar,
      UploadListingHandler,
      setFiles,
      selectMap,
      onChangeStreet,
      onChangeKP,
      onChangeSKL,
      confirmSuccessSubmit,
      descriptionValidation,
      setSelectedStudio,
    }}>
      {children}
      <ToastContainer />
    </UploadListingContext.Provider>
  )
}