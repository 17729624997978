import React, { useContext } from 'react'

//Components
import { Anchor } from "../../../../components"

//Assets
import XIcon from "../../../../assets/round-x-red.png"
import XGreenIcon from "../../../../assets/round-x-green.png"

//Context
import { UploadListingContext } from "../../../../contexts"

function ListingNavbar() {
  const { 
    process, 
    setProcess,
    savedIdentitasListing,
    savedSpekProp,
    savedGambar,
    spesifikasiPropertyValidation
  } = useContext(UploadListingContext)
  
  const currentProcess = (name) => name == process;
  
  const selectedClass = (name) => currentProcess(name) ? "current" : "";

  return (
    <div className='update-listing-navbar'>
      <div 
        onClick={() => setProcess("identitas-listing")} 
        className={`listing-navbar-tab ${selectedClass('identitas-listing')}`}
      >
        <Anchor>Identitas Listing</Anchor>
        <img src={savedIdentitasListing ? XGreenIcon : XIcon} alt="..." className='complete-icon' />
      </div>
      <div 
        onClick={() => !!spesifikasiPropertyValidation() && savedIdentitasListing && setProcess("spesifikasi-property")} 
        className={`listing-navbar-tab ${selectedClass('spesifikasi-property')}`}
      >
        <Anchor>Spesifikasi Properti</Anchor>
        <img src={(savedSpekProp && !!spesifikasiPropertyValidation()) ? XGreenIcon : XIcon} alt="..." className='complete-icon' />
      </div>
      <div 
        onClick={() => savedSpekProp && setProcess("upload-gambar")} 
        className={`listing-navbar-tab ${selectedClass("upload-gambar")}`}
      >
        <Anchor>Upload Gambar</Anchor>
        <img src={savedGambar ? XGreenIcon : XIcon} alt="..." className='complete-icon' />
      </div>
    </div>
  )
}

export default ListingNavbar